import React, { useRef, useEffect, useState } from 'react';
import { clamp } from 'helpers/fp';
import { getThrottledRAF } from 'helpers/layout';

import styles from './styles.scss';

const ScrollTracker = props => {
  const { baseHeight, color = '#000000' } = props;

  const scrollTracker = useRef();
  const isScrollTrackerFinished = useRef(false);
  const [isFull, setIsFull] = useState(false);

  useEffect(() => {
    const throttledRAF = getThrottledRAF();
    const scrollHandler = () =>
      throttledRAF(() => {
        const scrollTrackerPercentage = (window.pageYOffset / baseHeight) * 100;
        if (
          (scrollTrackerPercentage <= 100 || !isScrollTrackerFinished.current) &&
          scrollTracker.current
        ) {
          scrollTracker.current.style.width = `${clamp(0, 100)(scrollTrackerPercentage)}%`;
          setIsFull(false);
        } else {
          setIsFull(true);
        }
        isScrollTrackerFinished.current =
          scrollTrackerPercentage >= 100 || scrollTrackerPercentage <= 0;
      });
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [baseHeight]);

  return (
    <aside
      className={`scroll-tracker ${styles['scroll-tracker']}`}
      ref={scrollTracker}
      style={{ backgroundColor: color, visibility: isFull ? 'hidden' : 'visible' }}
    />
  );
};

export default ScrollTracker;
