import React from 'react';
import { withRouter } from 'react-router-dom';
import { get } from 'helpers/utilities';
import buildGTMData from 'helpers/buildGTMData';

// components
import InteractiveTitle from 'components/InteractiveTitle';
import ArticleBody from 'components/ArticleBody';
import Meta from 'components/Meta';
import NavigationThemeSetter from 'components/NavigationThemeSetter';

const Info = ({ active, infoContent }) => {
  const gtmData = buildGTMData(infoContent);

  if (!infoContent) return null;

  const { title, infoBody } = infoContent;
  const metaDetails = {
    metaSection: get(infoContent, 'socialMetaSection[0]'),
    entryTitle: get(infoContent, 'title'),
    noIndex: true
  };
  return (
    <>
      <Meta metaDetails={metaDetails} active={active} gtm={gtmData} />
      <NavigationThemeSetter navTheme="white">
        <InteractiveTitle title={title} />
        <ArticleBody
          data={infoBody}
          pageData={infoContent}
          leftRailIntroDetails={[]}
          theme="dark"
        />
      </NavigationThemeSetter>
    </>
  );
};

export default withRouter(Info);
