import React from 'react';

import styles from './styles.scss';

const hexToRgb = hexa => {
  const hex = hexa.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  const o = (r * 299 + g * 587 + b * 114) / 1000;

  return o > 125 ? 'black' : 'white';
};

const TlDr = ({ data, theme }) => {
  const { takeawaysPoints, backgroundElement, moduleTitle } = data;
  const { hex = '#000000' } = backgroundElement || {};

  return (
    <article
      className={`${styles.leftRailIntroDetails} ${
        theme === 'light' ? styles.leftRailIntroDetails__light : styles.leftRailIntroDetails__dark
      } ${styles.tldr} ${theme === 'light' ? styles.tldr__light : styles.tldr__dark}`}
      style={{ backgroundColor: hex, color: hexToRgb(hex) }}>
      <h2
        className={styles.leftRailTitle}
        dangerouslySetInnerHTML={{ __html: moduleTitle || 'TL;DR' }}
      />
      <ul>
        {takeawaysPoints.map((takeaway, index) => (
          <li key={index}>{takeaway.takeawayPointItem}</li>
        ))}
      </ul>
    </article>
  );
};

export default TlDr;
