import copy from 'copy-to-clipboard';

class Share {
  constructor({ socials, title, url, message }) {
    this.windowHeight = {
      facebook: 640,
      twitter: 252,
      linkedin: 476
    };

    this.types = [...socials];
    this.title = title;
    this.url = url;
    this.message = message || false;
  }

  /**
   * Get share url
   */
  getLink(type) {
    const { origin } = window.location;
    const _title = encodeURIComponent(this.title);
    const _url = encodeURIComponent(`${origin}/${this.url}`);
    const message600 = encodeURIComponent(this.truncate(this.message, 600));
    const message116 = encodeURIComponent(this.truncate(this.message, 116));
    const fb = `u=${_url}&title=${_title}`;

    const parameters = {
      facebook: !this.message ? `${fb}` : `${fb}&quote=${message600}`,
      twitter: !this.message
        ? `text=${_title}&url=${_url}`
        : `text=${message116}&via=${encodeURIComponent('codeandtheory')}`,
      linkedin: `url=${_url}`
    };

    const links = {
      facebook: `https://facebook.com/sharer/sharer.php?${parameters.facebook}`,
      twitter: `https://twitter.com/intent/tweet/?${parameters.twitter}`,
      linkedin: `https://www.linkedin.com/sharing/share-offsite/?${parameters.linkedin}`
    };

    return links[type];
  }

  /**
   * Open share window
   */
  openWindow(type) {
    const url = this.getLink(type);
    const modal = {
      options: 'scrollbars=yes,resizable=yes,toolbar=no,location=yes,',
      top: window.top.outerHeight / 2 + (window.top.screenY - this.windowHeight[type] / 2),
      left: window.top.outerWidth / 2 + (window.top.screenX - this.windowHeight[type] / 2),
      height: this.windowHeight[type],
      width: 555
    };

    window.open(
      url,
      '',
      `${modal.options},top=${modal.top},left=${modal.left},width=${modal.width},height=${modal.height}`
    );
  }

  /**
   * Copy to clipboard
   */
  copy(text, options = { debug: true, message: '' }) {
    copy(text, options);
  }

  truncate(string, length, ending = '...') {
    if (string.length > length) {
      return string.substring(0, length - ending.length) + ending;
    }

    return string;
  }
}

export default Share;
