import { get } from 'helpers/utilities';
import {
  parseGeneralContactModule,
  parseSpecializedContactModule
} from 'queries/contactModuleArticleBody';
import useGlobalContent from './useGlobalContent';

/**
 * Builds out contact data for the specified route
 * @param  {Object} entryData - the entry data
 * @return {Object}          - The contact data
 */
const useContactArticleBody = entryData => {
  const globalContent = useGlobalContent();

  /**
   * Build out proper contact data.
   * This is derived from the old ui/ContentLoaderContainer
   */
  const entryHasContactData =
    entryData && Array.isArray(entryData.contactModuleField) && entryData.contactModuleField.length;
  const isSpecializedContact =
    entryHasContactData &&
    Boolean(get(entryData, 'contactModuleField[0].specializedContactModule.length'));

  const globalContactOverlayData = get(globalContent, 'globalContactOverlay');
  const generalContactModule = get(globalContactOverlayData, 'generalContactModule');
  const generalContactData = entryHasContactData
    ? parseGeneralContactModule(get(entryData, 'contactModuleField[0].generalContactModule'))
    : parseGeneralContactModule(generalContactModule);
  const entryContactData = isSpecializedContact
    ? parseSpecializedContactModule(
        get(entryData, 'contactModuleField[0].specializedContactModule')
      )
    : generalContactData;

  const socials = isSpecializedContact ? [] : get(globalContent, 'socialLinksNoFb', []);

  return { ...globalContactOverlayData, ...entryContactData, socials };
};

export default useContactArticleBody;
