import React from 'react';
import { fromUnixTime, isSameDay, isSameYear, isSameMonth } from 'date-fns';
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import enUS from 'date-fns/locale/en-US';
import { createBlob } from 'blob-util';

import deviceInfo from 'helpers/deviceInfo';
import EventDetails from './EventDetails';

const EventDetailsContainer = props => {
  const { data, theme } = props;
  const { whatLabel, linkText, whereLabel } = data;

  function getTimezone() {
    const { timezone } = data;
    return timezone.length !== 1 ? 'America/New_York' : timezone[0].title;
  }

  function getStartTimeFormat(startTime, noTime = false) {
    if (isSameYear(new Date(), startTime)) return `EEEE, MMMM, do${noTime ? '' : ', h:mma'}`;
    return `EEEE, MMMM, yyyy, do${noTime ? '' : ', h:mma'}`;
  }

  function getEndTimeFormat(startTime, endTime, noTime = false) {
    if (isSameYear(new Date(), startTime)) {
      if (isSameMonth(startTime, endTime) && isSameDay(startTime, endTime)) return 'h:mma';
      return `EEEE, MMMM, do${noTime ? '' : ', h:mma'}`;
    }
    return `EEEE, MMMM, yyyy, do${noTime ? '' : ', h:mma'}`;
  }

  function getStartAndEndTime() {
    const { startDateAndTime, endDateAndTime } = data;

    const machineTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const startTime = startDateAndTime
      ? zonedTimeToUtc(fromUnixTime(startDateAndTime), machineTimeZone)
      : '';
    const endTime = endDateAndTime
      ? zonedTimeToUtc(fromUnixTime(endDateAndTime), machineTimeZone)
      : '';

    return { startTime, endTime };
  }

  function getWhenLabel() {
    const { allDay } = data;
    const { startTime, endTime } = getStartAndEndTime();
    const timeZone = getTimezone();

    const zonedStartTime = startTime ? utcToZonedTime(startTime, timeZone) : '';
    const zonedEndTime = endTime ? utcToZonedTime(endTime, timeZone) : '';

    const formattedTimezone = startTime
      ? format(zonedStartTime, 'zzz', { timeZone, locale: enUS })
      : '';
    const formattedStartTime = startTime
      ? format(zonedStartTime, getStartTimeFormat(zonedStartTime, allDay), {
          timeZone,
          locale: enUS
        })
      : '';
    const formattedEndTime =
      endTime && startTime
        ? format(zonedEndTime, `- ${getEndTimeFormat(zonedStartTime, zonedEndTime, allDay)}`, {
            timeZone,
            locale: enUS
          })
        : '';

    return `${formattedStartTime} ${formattedEndTime} ${!allDay ? formattedTimezone : ''}`.trim();
  }

  function buildUrl({ whatLabel, whereLabel, linkText }, type, isIE) {
    let calendarUrl = '';
    const { startTime, endTime } = getStartAndEndTime();

    const machineTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const calendarTimeFormat = `yyyyMMdd'T'HHmmss`;
    const calendarStartTime = startTime
      ? format(utcToZonedTime(startTime, machineTimeZone), calendarTimeFormat)
      : '';
    const calendarEndTime = endTime
      ? format(utcToZonedTime(endTime, machineTimeZone), calendarTimeFormat)
      : '';

    switch (type) {
      case 'google':
        calendarUrl = `https://calendar.google.com/calendar/render/?action=TEMPLATE&dates=${calendarStartTime}/${calendarEndTime}&location=${encodeURIComponent(
          whereLabel
        )}&text=${encodeURIComponent(whatLabel)}&details=${encodeURIComponent(linkText)}`;
        break;

      default:
        calendarUrl = [
          'BEGIN:VCALENDAR',
          'VERSION:2.0',
          'BEGIN:VEVENT',
          `URL:${document.URL}`,
          `DTSTART:${calendarStartTime}`,
          `DTEND:${calendarEndTime}`,
          `SUMMARY:${whatLabel}`,
          `DESCRIPTION:${linkText}`,
          `LOCATION:${whereLabel}`,
          'END:VEVENT',
          'END:VCALENDAR'
        ].join('\n');

        if (!isIE && (deviceInfo.isAndroid || deviceInfo.isIOS)) {
          calendarUrl = encodeURI(`data:text/calendar;charset=utf8,${calendarUrl}`);
        }
    }

    return calendarUrl;
  }

  function ieStartsWith(str, word) {
    return str.lastIndexOf(word, 0) === 0;
  }

  function handleClick(e) {
    e.preventDefault();

    const url = buildUrl(
      data,
      deviceInfo.isAndroid || deviceInfo.isIOS ? 'google' : 'other',
      deviceInfo.isIE11
    );

    if (!deviceInfo.isTouch && (ieStartsWith(url, 'data') || ieStartsWith(url, 'BEGIN'))) {
      const filename = 'event.ics';
      const blob = createBlob([url], { type: 'text/calendar;charset=utf-8' });

      if (deviceInfo.isIE11) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else if (deviceInfo.isTouch) {
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(url, '_blank');
    }
  }

  return (
    <EventDetails
      whatLabel={whatLabel}
      linkText={linkText}
      whenLabel={getWhenLabel()}
      whereLabel={whereLabel}
      handleClick={handleClick}
      theme={theme}
    />
  );
};

export default EventDetailsContainer;
