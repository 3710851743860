import React from 'react';
import { get } from 'helpers/utilities';
import ProjectSlider from 'components/ProjectSlider';

import styles from './styles.scss';

// TO DO use flex instead of grid to remove variant prop
// TO DO: Since the design of about us services cards /components/AboutUsPackageCards
// is gradually changing, this can be refactor to work only
// on curated portfolio cards like removing flags and other props
// that handle the requirement of about us service cards
const PackageCards = ({ eyebrow, moduleTitle, cards = [], className }, ref) => (
  <section className={`${styles.packageCards} ${className}`} ref={ref}>
    {eyebrow ? <p className={styles.eyebrow}>{eyebrow}</p> : null}
    <h2
      className={styles.moduleTitle}
      dangerouslySetInnerHTML={{ __html: get(moduleTitle, 'content', '') }}
    />
    <ProjectSlider cards={cards} total={cards.length} infinite={false} />
  </section>
);

export default React.forwardRef(PackageCards);
