import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { get } from 'helpers/utilities';

import styles from './styles.scss';

const AccoladesItemContent = ({ recognitionTitle, awardName, awardLink, accoladesLinkType }) =>
  awardLink ? (
    <>
      <dt>
        {accoladesLinkType === 'internalLink' ? (
          <Link to={`/${awardLink}`} className={styles.accolodes_link}>
            {recognitionTitle}
          </Link>
        ) : (
          <a href={awardLink} className={styles.accolodes_link}>
            {recognitionTitle}
          </a>
        )}
      </dt>
      <dd>{awardName}</dd>
    </>
  ) : (
    <>
      <dt>{recognitionTitle}</dt>
      <dd>{awardName}</dd>
    </>
  );

const Accolades = ({ data, theme }) => {
  const { accoladesItem } = data;
  return (
    <article
      className={`${styles.leftRailIntroDetails} ${
        theme === 'light' ? styles.leftRailIntroDetails__light : styles.leftRailIntroDetails__dark
      } ${styles.accolades} ${
        theme === 'light' ? styles.accolades__light : styles.accolades__dark
      }`}>
      <h2 className={styles.leftRailTitle} dangerouslySetInnerHTML={{ __html: 'Accolades' }} />
      <dl>
        {accoladesItem.map((accolade, index) => (
          <AccoladesItemContent
            key={index}
            {...accolade}
            linkType={get(accolade, 'accoladesLinkType')}
            awardLink={
              get(accolade, 'accoladesLinkType') === 'internalLink'
                ? get(accolade, 'awardInternalLink[0].uri')
                : get(accolade, 'awardExternalLink')
            }
          />
        ))}
      </dl>
    </article>
  );
};

export default Accolades;
