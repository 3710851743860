import React, { useRef } from 'react';
import { get } from 'helpers/utilities';
import { withSlider } from 'components/Slider';

// related components
import CardList from './CardList';
import SliderButton from './SliderButton';
import ProjectDescriptionList from './ProjectDescriptionList';
import useBreakpoint from '../../hooks/useBreakpoint';

import styles from './styles.scss';

const ProjectSlider = ({ total, current, next, prev, setCurrent, cards = [] }) => {
  const cardRefs = useRef([]);
  const breakpoint = useBreakpoint();

  const lineOffsetLeft = get(cardRefs.current[current], 'offsetLeft', 0);

  return (
    <div className={styles.projectSlider}>
      <CardList
        cards={cards}
        current={current}
        cardRefs={cardRefs}
        next={next}
        prev={prev}
        setCurrent={setCurrent}
        total={total}
        style={breakpoint === 'largeDesktop' ? { gridColumn: `1 / span ${cards.length * 2}` } : {}}
      />
      <ProjectDescriptionList
        cards={cards}
        current={current}
        style={
          breakpoint === 'largeDesktop' ? { gridColumn: `${cards.length * 2 + 1} / span 4` } : {}
        }
      />
      <div className={styles.projectSlider__line}>
        <div style={{ left: lineOffsetLeft }} />
      </div>
      <div className={styles.projectSlider__control}>
        <SliderButton
          onClick={prev}
          direction="left"
          className={current === 0 ? styles.projectSlider__control__arrow_inactive : ''}
        />
        <span className={styles.projectSlider__control__label}>
          {current + 1}/{total}
        </span>
        <SliderButton
          onClick={next}
          direction="right"
          className={current === total - 1 ? styles.projectSlider__control__arrow_inactive : ''}
        />
      </div>
    </div>
  );
};

export default withSlider(ProjectSlider);
