import React from 'react';
import Icon from 'components/Icon';

import styles from './styles.scss';

const EventDetails = ({ whatLabel, linkText, whenLabel, whereLabel, handleClick, theme }) => (
  <article
    className={`${styles.leftRailIntroDetails} ${
      theme === 'light' ? styles.leftRailIntroDetails__light : styles.leftRailIntroDetails__dark
    } ${styles.eventDetails} ${
      theme === 'light' ? styles.eventDetails__light : styles.eventDetails__dark
    }`}>
    {whatLabel ? (
      <div className={styles.eventDetailsItem}>
        <h2 className={styles.leftRailTitle} dangerouslySetInnerHTML={{ __html: 'What' }} />
        <a
          href={linkText}
          title={whatLabel}
          className={`${styles.leftRailLink} ${styles.eventDetails__link}`}
          target="_blank"
          rel="noopener noreferrer">
          {whatLabel}
          <span />
        </a>
      </div>
    ) : null}

    {whereLabel ? (
      <div className={styles.eventDetailsItem}>
        <h2 className={styles.leftRailTitle}>
          <Icon name="location" classes={styles.eventDetailsIcon} />
          Where
        </h2>
        <address>{whereLabel}</address>
      </div>
    ) : null}

    {whenLabel ? (
      <div className={styles.eventDetailsItem}>
        <h2 className={styles.leftRailTitle} dangerouslySetInnerHTML={{ __html: 'When' }} />
        <time>{whenLabel}</time>
      </div>
    ) : null}

    {whatLabel && whenLabel ? (
      <div className={`${styles.eventDetailsItem} ${styles.eventDetailsItem}--button`}>
        <button onClick={handleClick} type="button">
          Add to Calendar
        </button>
      </div>
    ) : null}
  </article>
);

export default EventDetails;
