import React from 'react';
import { removeInfoSlug } from 'helpers/text';

import styles from './styles.scss';

const ArticleBreakerWithLinks = props => {
  const { data } = props;
  const { headline, subhead = null, blockLinks = [] } = data;
  const hasBlockLinks = blockLinks.length > 0;
  return (
    <section className={`${styles.container}`} data-full-width>
      <div className={`${styles.content} wrapper`}>
        <div className={`${styles.content__headline}`}>
          <div
            className={`${styles.content__headlineTitle}`}
            dangerouslySetInnerHTML={{ __html: removeInfoSlug(headline.content) }}
          />
          {subhead && (
            <div
              className={`${styles.content__subTitle}`}
              dangerouslySetInnerHTML={{ __html: removeInfoSlug(subhead.content) }}
            />
          )}
        </div>

        {hasBlockLinks && (
          <div className={`${styles.content__links}`}>
            {blockLinks.map((blockLink, index) => {
              const { linkItems } = blockLink;

              if (!linkItems && linkItems.length <= 0) return null;

              const blockLinkItems = linkItems.map((link, idx) => {
                const { linkUrl, linkLabel } = link;
                return (
                  <li key={idx}>
                    <a href={linkUrl} title={linkLabel}>
                      {linkLabel} <span>—</span>
                    </a>
                  </li>
                );
              });
              return <ul key={index}>{blockLinkItems}</ul>;
            })}
          </div>
        )}
      </div>
    </section>
  );
};

export default ArticleBreakerWithLinks;
