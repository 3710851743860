import React, { useRef, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { get, find } from 'helpers/utilities';
import classNames from 'classnames/bind';
import StickyFill from 'stickyfill';
import { EVENT, breakpoints } from 'config/constants';
import EventHandler from 'helpers/EventHandler';
import { isStructValid } from 'helpers/structure';
import { compose, isDefined } from 'helpers/fp';
import { safeRender } from 'helpers/layout';
import canUseDOM from 'helpers/canUseDOM';

import { withResizeDetector } from 'react-resize-detector';

// components
import ChapterMarker from 'components/ChapterMarker';
import LeftRailIntroDetails from 'components/LeftRailIntroDetails';
import PersistentShare from 'components/PersistentShare';
import ScrollTracker from 'components/ScrollTracker';
import OpenTextRailCard from 'components/OpenTextRailCard';

import Features from 'components/Features';
import withStickyFilterSetter from 'components/withStickyFilterSetter';
import articleQueries from 'queries/articles';
import EmailSignup from 'components/EmailSignup';

import useTimeout from '../../hooks/useTimeout';
import useDebounce from '../../hooks/useDebounce';
import useBreakpoint from '../../hooks/useBreakpoint';

import ArticleModules from './ArticleModules';
import ArticleModulesStruct from './ArticleModulesStruct';

import styles from './styles.scss';
import ContactArticleBody from '../ContactArticleBody';

const cx = classNames.bind(styles);

const FeaturesWithStickyFilter = withStickyFilterSetter({
  componentName: 'features',
  color: 'white'
})(Features);

/**
 * @NOTE: Return Components to be rendered
 * This rendering is for components under ArticleBody
 */

const renderComponents = (components, isOdd, theme, slug = null) => {
  if (!components.length) {
    return null;
  }

  const dynamicallyRenderedComponents = components.map((component, index) => {
    const componentTitle = get(component, '__typename');

    if (componentTitle === 'ArticleBodyOpenTextRailCard') {
      return <OpenTextRailCard data={component} oddEven={isOdd} theme={theme} key={index} />;
    }

    if (componentTitle === 'ArticleBodyContactModule') {
      return <ContactArticleBody data={component} />;
    }

    if (componentTitle === 'ArticleBodyFeaturedEntries') {
      const { featuresModule } = component;

      return (
        <FeaturesWithStickyFilter
          key={index}
          features={featuresModule}
          componentQuery={articleQueries.SinglePage.spFeatures(slug)}
          isArticleBody={true}
        />
      );
    }

    if (componentTitle === 'ArticleBodyEmailSignUp') {
      const { emailSignUpTitle, emailSignUpBody, emailSignUpSuccessMessage } = component;

      return (
        <EmailSignup
          title={emailSignUpTitle}
          body={emailSignUpBody}
          successMessage={emailSignUpSuccessMessage ? emailSignUpSuccessMessage.content : undefined}
        />
      );
    }

    const Module = ArticleModules[componentTitle];
    const Struct = ArticleModulesStruct[componentTitle];

    if (!Module || !Struct) return null;
    if (!isStructValid(Struct, component)) {
      return null;
    }
    const { Component: ArticleComponent, staticProps } = Module;

    return (
      <ArticleComponent {...staticProps} data={component} key={index} isOdd={false} theme={theme} />
    );
  });

  return dynamicallyRenderedComponents;
};

const ArticleGroup = props => {
  const { breakpoint, contents = [], index, theme, last = false, pageData } = props;
  const railRef = useRef();
  const contentRef = useRef();

  // const { contents = [], index, theme, last = false } = this.props;

  const isFirstComponentAChapterMarker =
    get(contents[0], '__typename', '') === 'ArticleBodyChapterMarker';
  const isOdd = (index + 1) % 2;
  const articleGroupClasses = cx('articleGroup', 'wrapper', {
    'articleGroup--odd': isOdd,
    'articleGroup--even': !isOdd,
    'articleGroup--hasChapter': isFirstComponentAChapterMarker,
    articleGroup__light: theme === 'light',
    articleGroup__dark: theme === 'dark',
    articleGroup__last: last
  });

  const pageSlug = pageData && pageData.slug ? pageData.slug : null;

  function updateContentRefChildMinHeight(
    contentRefChildren,
    idx,
    accumulatedHeight,
    chapterMarkerHeight
  ) {
    const currentContentRefChild = contentRefChildren[idx];
    const nextContentRefChild = contentRefChildren[idx + 1];

    if (
      currentContentRefChild &&
      nextContentRefChild &&
      chapterMarkerHeight - accumulatedHeight > 0
    ) {
      if (nextContentRefChild.getAttribute('data-full-width') === 'true')
        currentContentRefChild.style.minHeight = `calc(${
          chapterMarkerHeight - accumulatedHeight
        }px - ${window.getComputedStyle(currentContentRefChild).marginBottom})`;
      else {
        currentContentRefChild.style.minHeight = '';
        updateContentRefChildMinHeight(
          contentRefChildren,
          idx + 1,
          accumulatedHeight + currentContentRefChild.clientHeight,
          chapterMarkerHeight
        );
      }
    }
  }

  function updateContentMinHeight() {
    if (railRef.current && contentRef.current) {
      const chapterMarker = get(railRef.current, 'firstChild.firstChild');
      const contentRefChildren = get(contentRef.current, 'children');
      if (chapterMarker && contentRefChildren.length && canUseDOM) {
        updateContentRefChildMinHeight(contentRefChildren, 0, 0, chapterMarker.clientHeight);
        updateContentRefChildMinHeight(
          [...contentRefChildren].reverse(),
          0,
          0,
          chapterMarker.clientHeight
        );
      }
    }
  }

  // componentDidMount
  useEffect(() => {
    updateContentMinHeight();
  }, []);

  // componentDidUpdate
  useEffect(() => {
    updateContentMinHeight();
  }, [breakpoint]);

  return (
    <section className={articleGroupClasses}>
      <div className={styles.articleGroup__rail} ref={railRef}>
        {isFirstComponentAChapterMarker ? (
          <ChapterMarker data={contents[0]} oddEven={isOdd} theme={theme} />
        ) : null}
      </div>
      <div className={styles.articleGroup__content} ref={contentRef}>
        {renderComponents(contents, isOdd, theme, pageSlug)}
      </div>
    </section>
  );
};

const ArticleLeftRail = props => {
  const {
    breakpoint,
    contents = [],
    rails = [],
    pageData,
    isCuratedPortfolio,
    theme,
    last = false
  } = props;
  const leftRailRef = useRef();
  const leftContentRef = useRef();
  const [leftRailContentHeight, setLeftRailContentHeight] = useState(0);

  function getLeftRailContentHeight() {
    const leftRailElement = leftRailRef.current;
    if (!leftRailElement || leftRailElement.children.length === 0) return 0;
    let totalContentHeight = 0;
    Array.from(leftRailRef.current.children).forEach(
      el => (totalContentHeight += el.clientHeight + 32) // 32 is for the margin
    );
    return totalContentHeight;
  }

  // componentDidMount
  useEffect(() => {
    // get left rail sum of children height
    setLeftRailContentHeight(getLeftRailContentHeight());

    // heck article group content for full width component
  }, [leftRailContentHeight, breakpoint]);

  const articleClasses = cx('articleGroup', {
    'articleGroup--tldr': true,
    'articleGroup--portfolio': isCuratedPortfolio,
    articleGroup__light: theme === 'light',
    articleGroup__dark: theme === 'dark',
    articleGroup__last: last
  });

  const pageSlug = pageData && pageData.slug ? pageData.slug : null;

  useEffect(() => {
    const leftRailElem = leftRailRef.current;
    const leftContentElem = leftContentRef.current;
    const leftRailInner = leftRailRef.current.firstElementChild;

    if (!(leftRailElem && leftContentElem && leftRailInner)) return;
    const pushFullwithSectionFromLeftRail = () => {
      Array.prototype.forEach.call(leftContentElem.children, (el, i) => {
        const fullWidthEl = el;
        fullWidthEl.style.marginTop = '';
        if (!el.hasAttribute('data-full-width')) return;
        const leftRailDistance =
          leftRailInner.getBoundingClientRect().top + leftRailInner.getBoundingClientRect().height;
        const rightContentDistance = el.getBoundingClientRect().top;
        if (!(leftRailDistance > rightContentDistance)) return;
        const getOverlapDistance = leftRailDistance - rightContentDistance;
        const prevContentElem =
          leftContentElem.children[i - 1] &&
          window.getComputedStyle(leftContentElem.children[i - 1]);
        const prevElemBottomSpacing = prevContentElem
          ? parseFloat(prevContentElem.paddingBottom) + parseFloat(prevContentElem.marginBottom)
          : 0;
        const newMarginTop = `${getOverlapDistance + prevElemBottomSpacing}px`;
        fullWidthEl.style.marginTop = newMarginTop;
      });
    };
    pushFullwithSectionFromLeftRail();

    window.addEventListener('resize', pushFullwithSectionFromLeftRail);

    return () => window.removeEventListener('resize', pushFullwithSectionFromLeftRail);
  }, [leftRailRef, leftContentRef]);

  return (
    <section className={articleClasses}>
      <div className={styles.articleGroup__rail} ref={leftRailRef}>
        {rails.map((rail, index) => {
          const leftRailData =
            get(rail, '__typename') !== 'LeftRailIntroDetailsPublishDate'
              ? rail
              : { ...pageData, __typename: 'LeftRailIntroDetailsPublishDate' };
          return <LeftRailIntroDetails data={leftRailData} key={index} theme={theme} />;
        })}
      </div>
      <div className={styles.articleGroup__content} ref={leftContentRef}>
        {renderComponents(contents, false, theme, pageSlug)}
      </div>
    </section>
  );
};

const groupArticleComponents = components => {
  let currentGroup = 'leftRailBody';
  let currentGroupIndex;

  return components.reduce(
    (result, component) => {
      const componentName = get(component, '__typename');

      if (componentName === 'ArticleBodyChapterMarker') {
        currentGroup = 'articleGroups'; // switch to body group after chapter marker is detected
        currentGroupIndex = isDefined(currentGroupIndex) ? currentGroupIndex + 1 : 0;
      }

      if (currentGroup === 'articleGroups') {
        result[currentGroup][currentGroupIndex] = result[currentGroup][currentGroupIndex] || [];
        result[currentGroup][currentGroupIndex].push(component);
      } else {
        result[currentGroup].push(component);
      }

      return result;
    },
    { leftRailBody: [], articleGroups: [] }
  );
};

const ArticleBodyContainer = props => {
  // Props
  const {
    noScrollTracker = false,
    data,
    leftRailIntroDetails,
    pageData,
    theme = 'light',
    isCuratedPortfolio,
    articleContentStyle
  } = props;
  const articleContainerRef = useRef();

  const timeout = useTimeout();
  const debounce = useDebounce();
  const breakpoint = useBreakpoint();

  // Variables
  let chapters = [];
  let metricsRail = [];
  let __EVENT_RESIZE = null;
  let stickyFill = null;

  const tldr = Array.isArray(leftRailIntroDetails)
    ? find(leftRailIntroDetails, ({ __typename }) => __typename === 'LeftRailIntroDetailsTlDr')
    : {};
  const articleContainerClass = cx(
    '',
    {
      'articleContainer--white': theme === 'light',
      'articleContainer--black': theme === 'dark'
    },
    articleContentStyle
  );
  const scrollTrackerBaseHeight =
    get(articleContainerRef, 'current.offsetHeight', 0) +
    get(articleContainerRef, 'current.offsetTop', 0) -
    get(document, 'documentElement.clientHeight', 0);

  const { leftRailBody, articleGroups } = groupArticleComponents(data);

  /*
    // There is a shouldComponentUpdate method from the class component
    // which doesn't seem to do much, but leaving here just for reference

    shouldComponentUpdate(nextProps) {
        const { height, data } = this.props;

        if (get(nextProps, 'data') !== data) {
            return true;
        }

        if (get(nextProps, 'height') !== height) {
            return true;
        }

        return false;
    }
     */

  function setChapterStyling() {
    for (let i = 0; i < chapters.length; i++) {
      const $chapter = chapters[i];
      const $metrics = $chapter.parentElement.parentElement.querySelector('.metricsRailCard');

      $chapter.style.marginBottom =
        $metrics && window.innerWidth >= breakpoints.smallDesktop
          ? `${$metrics.clientHeight}px`
          : '40px';

      if ($metrics) {
        $metrics.style.top = `${$chapter.clientHeight}px`;
      }
    }
  }

  function unSetStickyPolyfill() {
    if (!stickyFill) return;

    Array.from(metricsRail).forEach(metric => {
      stickyFill.remove(metric);
    });

    stickyFill.stop();
    stickyFill = null;
  }

  function setStickyPolyfill() {
    const articleContainerElement = articleContainerRef.current;
    if (!articleContainerElement || !stickyFill) return;

    metricsRail = articleContainerElement.querySelectorAll(`.${styles.metricsRailCard}`);

    Array.from(metricsRail).forEach(metric => {
      stickyFill.add(metric);
    });
  }

  function resizeHandler() {
    setChapterStyling();
  }

  // componentDidMount
  useEffect(() => {
    chapters = articleContainerRef.current.querySelectorAll('.chapterMarker');

    __EVENT_RESIZE = EventHandler.subscribe(EVENT.RESIZE, debounce(resizeHandler, 500));

    timeout(() => {
      stickyFill = new StickyFill();
      setChapterStyling();
      setStickyPolyfill();
    }, 300);

    // Cleanup
    return () => {
      unSetStickyPolyfill();
      EventHandler.unsubscribe(__EVENT_RESIZE);
    };
  }, []);

  return (
    <article className={`article ${articleContainerClass}`} ref={articleContainerRef}>
      {safeRender(
        leftRailBody.length || leftRailIntroDetails.length, // should not render if no left rail body is found or a left rail components
        <ArticleLeftRail
          breakpoint={breakpoint}
          contents={leftRailBody}
          rails={leftRailIntroDetails}
          pageData={pageData}
          isCuratedPortfolio={isCuratedPortfolio}
          theme={theme}
          last={articleGroups.length === 0}
        />
      )}

      {articleGroups.map((articleGroupBody, index) => (
        <ArticleGroup
          breakpoint={breakpoint}
          contents={articleGroupBody}
          index={index}
          key={`ArticleGroup${index}`}
          theme={theme}
          last={articleGroups.length - 1 === index}
          pageData={pageData}
        />
      ))}

      {!noScrollTracker && (
        <PersistentShare
          title={get(pageData, 'title')}
          url={get(pageData, 'uri')}
          socials={['facebook', 'twitter', 'linkedin', 'email']}
        />
      )}

      {!noScrollTracker && (
        <ScrollTracker
          color={get(tldr, 'backgroundElement.hex')}
          baseHeight={scrollTrackerBaseHeight}
        />
      )}
    </article>
  );
};

export default compose(withRouter, withResizeDetector)(ArticleBodyContainer);
