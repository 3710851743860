import React, { useState } from 'react';
import Modal from 'components/Modal';

import styles from './styles.scss';

const LoginModal = props => {
  const { onClickClose, onClickCancel, onExited, onClickSubmit, show, error, loading } = props;

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const formData = { username, password };

  return (
    <Modal
      show={show}
      onClickClose={onClickClose}
      onExited={onExited}
      containerClassName={styles.loginModal}>
      <form onSubmit={e => onClickSubmit(e, formData)} className={styles.loginModal__form}>
        {error && <span className={styles.loginModal__form_error}>{error}</span>}
        <label htmlFor="username">Username:</label>
        <input
          type="text"
          name="username"
          value={formData.username}
          onChange={e => setUsername(e.target.value)}
        />
        <label htmlFor="password">Password:</label>
        <input
          type="password"
          name="password"
          value={formData.password}
          onChange={e => setPassword(e.target.value)}
        />
        <section className={styles.loginModal__form_footer}>
          <button type="button" name="cancel" onClick={onClickCancel}>
            Cancel
          </button>
          <button type="submit" name="submit" disabled={loading}>
            {loading ? 'Logging in' : 'Login'}
          </button>
        </section>
      </form>
    </Modal>
  );
};

export default LoginModal;
