import { useQuery } from '@apollo/react-hooks';
import { get } from 'helpers/utilities';
import articlesQuery from 'queries/articles';
import awardsModuleQuery from 'queries/awardsModule';

const useAwardsContent = articleContentData => {
  const awardsQuery = articlesQuery[get(articleContentData, '__typename')].component(
    get(articleContentData, 'uri'),
    awardsModuleQuery
  );
  const { loading, error, data } = useQuery(awardsQuery);

  if (loading || error) {
    return null;
  }

  const awards = get(data, 'entry.awardsModule[0].awards[0]', null);

  return awards;
};

export default useAwardsContent;
