import React, { useState, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames/bind';
import Icon from 'components/Icon';
import Share from 'helpers/Share';
import deviceInfo from 'helpers/deviceInfo';
import { socialShare } from 'helpers/dataLayer';
import Stickyfill from 'stickyfill';
import FocusLock from 'react-focus-lock';
import useTimeout from '../../hooks/useTimeout';

import styles from './styles.scss';

const cx = classNames.bind(styles);

const PersistentShare = props => {
  const { socials, shareRef: shareRefProp, title, url } = props;
  const timeout = useTimeout();
  // States
  const [isOpen, setIsOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  // Refs
  const shareRef = shareRefProp || useRef(); // DOM ref
  const toggleRef = useRef();
  const lastRef = useRef();
  const ShareRef = useRef(); // Share instance ref
  const StickyFillRef = useRef(null);

  const shareClass = cx('share', { 'share--open': isOpen });
  const message = 'This share link has been copied to your clipboard';

  // componentDidMount
  useEffect(() => {
    ShareRef.current = new Share({
      title,
      url,
      socials
    });

    if (deviceInfo.isTouch) {
      document.addEventListener('touchend', documentClick);
      return;
    }

    if (deviceInfo.isIE11) {
      StickyFillRef.current = new Stickyfill();
      StickyFillRef.current.add(shareRef.current);
    }

    document.addEventListener('click', documentClick);

    // Cleanup
    return () => {
      if (deviceInfo.isTouch) {
        document.removeEventListener('touchend', documentClick);
        return;
      }

      if (deviceInfo.isIE11) {
        StickyFillRef.current.remove(shareRef.current);
        StickyFillRef.current.stop();
      }

      document.removeEventListener('click', documentClick);
    };
  }, []);

  // Component did update
  useEffect(() => {
    if (isCopied) {
      ShareRef.current.copy(window.location);

      timeout(() => {
        setIsCopied(false);
      }, 3000);
    }
  }, [url, timeout, isCopied, isOpen]);

  function documentClick(e) {
    if (!shareRef.current) return;

    const isClickInside = shareRef.current.contains(e.target);

    if (!isClickInside && isOpen) {
      toggleShare();
    }
  }

  function clickSocialHandler(type) {
    socialShare(type);

    if (type === 'email') {
      setIsCopied(true);
      return;
    }

    ShareRef.current.openWindow(type);
  }

  function toggleShare(show) {
    setIsOpen(show === undefined ? !isOpen : show);
    setIsCopied(false);
  }

  function mouseEnterHandler() {
    if (deviceInfo.isTouch) {
      return;
    }

    toggleShare(true);
  }

  return (
    <aside
      ref={shareRef}
      className={shareClass}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={() => toggleShare(false)}>
      <button
        type="button"
        tabIndex="0"
        ref={toggleRef}
        className={styles.share__button}
        onClick={() => toggleShare(!isOpen)}
        // aria-live={"assertive"}
        aria-pressed={isOpen}
        aria-label={isOpen ? 'Close, Persistent Share' : 'Open, Persistent Share'}>
        <span>Share</span>
        <span>Close</span>
      </button>
      <CSSTransition
        in={isOpen}
        timeout={isOpen ? 200 : 600}
        classNames={styles.share__box}
        unmountOnExit>
        <div className={styles.share__box}>
          <CSSTransition
            in={isCopied}
            timeout={300}
            classNames={styles.share__tooltip}
            unmountOnExit>
            <div className={styles.share__tooltip}>{message}</div>
          </CSSTransition>
          <FocusLock disabled={!isOpen} shards={[shareRef]}>
            {socials.map((social, index) => (
              <button
                key={index}
                type="button"
                ref={index === socials.length - 1 ? lastRef : null}
                className={styles.share__social}
                title={social}
                onClick={() => clickSocialHandler(social)}
                tabIndex={isOpen ? '0' : '-1'}
                aria-label={`Share via ${social}`}>
                <Icon name={social} classes={styles.share__social__icon} />
              </button>
            ))}
          </FocusLock>
        </div>
      </CSSTransition>
    </aside>
  );
};

export default PersistentShare;
