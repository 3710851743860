import React from 'react';
import { removeInfoSlug } from 'helpers/text';

import styles from './styles.scss';

const OpenWyswyg = ({ data, theme }) => {
  const { contentArea } = data;

  return (
    <article
      className={`
                ${styles.leftRailIntroDetails}
                ${
                  theme === 'light'
                    ? styles.leftRailIntroDetails__light
                    : styles.leftRailIntroDetails__dark
                }
                ${styles.links}
                ${theme === 'light' ? styles.links__light : styles.links__dark}
                ${styles.wysiwyg}
                ${theme === 'light' ? styles.wysiwyg__light : styles.wysiwyg__dark}
            `}
      dangerouslySetInnerHTML={{ __html: removeInfoSlug(contentArea.content) }}
    />
  );
};

export default OpenWyswyg;
