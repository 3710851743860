import React from 'react';
import { get } from 'helpers/utilities';
import Image from 'components/Image';
import { stripParagraphTag } from 'helpers/text';

import styles from './styles.scss';

const imageSizes = {
  mobile: 600,
  smallDesktop: 550
};

const InlinePortraitImage = ({ data, className }) => {
  const { asset, caption } = data;
  const media = get(asset, '[0]', []);

  return (
    <figure className={`toolkit inlinePortraitImage ${styles.inlinePortraitImage} ${className}`}>
      <div className={styles.inlinePortraitImage__image}>
        <div className={styles.inlinePortraitImage__image__container}>
          <Image src={media.url} alt={media.title} lazyload={false} sizes={imageSizes} />
        </div>
      </div>
      {caption && caption.content && caption.content !== '' ? (
        <figcaption
          className={styles.inlinePortraitImage__caption}
          dangerouslySetInnerHTML={{ __html: stripParagraphTag(caption.content) }}
        />
      ) : null}
    </figure>
  );
};

export default InlinePortraitImage;
