import cardQueries from './cardQueries';
import contactModuleArticleBody from './contactModuleArticleBody';

const articleBody = {
  wrapper: components => `
        articleBody {
            __typename
            ${components}
        }
    `,
  ArticleBodyChapterMarker: `
        ... on ArticleBodyChapterMarker {
            chapterMarkerTitle
            number
        }
    `,
  ArticleBodyMetricsBreaker: `
    ... on ArticleBodyMetricsBreaker {
            moduleTitle
            moduleDescription {
                content
            }
            enableAnimation
			metricsCards {
				digits
				textBeforeDigits
				textAfterDigits
				cardDescription {
					content
				}
			}
    }
    `,
  ArticleBodyOpenWyswyg: `
    ... on ArticleBodyOpenWyswyg {
        text {
            content
        }
    }
`,
  ArticleBodyPullQuote: `
        ... on ArticleBodyPullQuote {
            blurb {
                content
            }
            pullQuoteSource
            description
        }
    `,
  ArticleBodyInlinePortraitImage: `
        ... on ArticleBodyInlinePortraitImage {
            caption {
                content
            }
            asset {
                url
                title
                extension
            }
        }
    `,
  ArticleBodyFullWidthMediaEmbed: `
        ... on ArticleBodyFullWidthMediaEmbed {
            videoType
            caption {
                content
            }
            videoTitle {
                content
            }
            imageAsset {
                url
                title
                extension
            }
            videoAsset {
                url
                title
            }
            videoNaviteAsset {
                url
                title
            }
            enableParallax
            enableVideoAutoplay
        }
    `,
  ArticleBodyContactModule: `
    ... on ArticleBodyContactModule {
      ${contactModuleArticleBody}
    }
  `,
  ArticleBodyMediaEmbed: `
        ... on ArticleBodyMediaEmbed {
            videoType
            caption {
                content
            }
            videoTitle {
                content
            }
            imageAsset {
                url
                title
                extension
            }
            videoAsset {
                url
                title
            }
            videoNaviteAsset {
                url
                title
            }
            enableVideoAutoplay
        }
    `,
  ArticleBodyCarousel: `
        ... on ArticleBodyCarousel {
            item {
                caption {
                    content
                }
                asset {
                    url
                    title
                    extension
                }
            }
            backgroundColor {
                hex
            }
            elementsColor
        }
    `,
  ArticleBodyScrollJackModule: `
        ... on ArticleBodyScrollJackModule {
            asset {
                url
                mimeType
                width
                height
            }
            caption {
                content
            }
        }
    `,
  ArticleBodyAlternatingZoomModule: `
        ... on ArticleBodyAlternatingZoomModule {
            item {
                asset {
                    url
                    title
                }
                caption {
                    content
                }
            }
        }
    `,
  ArticleBodyZoomingImageBreaker: `
        ... on ArticleBodyZoomingImageBreaker {
            item {
                asset {
                    url
                    title
                    extension
                }
            }
        }
    `,
  ArticleBodyProjectBreaker: `
        ... on ArticleBodyProjectBreaker {
            projectBreakerCaption {
                content
            }
            projectBreakerDescription
            projectBreakerCard {
                id
                title
                uri
                postDate
                ${cardQueries.article}
                ${cardQueries.project}
                ${cardQueries.curatedWork}
            }
        }
    `,
  ArticleBodyRelatedRailCard: `
        ... on ArticleBodyRelatedRailCard {
            card {
                id
                title
                uri
                postDate
                ${cardQueries.article}
                ${cardQueries.project}
                ${cardQueries.account}
                ${cardQueries.curatedWork}
            }
        }
    `,
  ArticleBodyMetricsRailCard: `
        ... on ArticleBodyMetricsRailCard {
            statTitle
            statNumber
            text {
                content
            }
        }
    `,
  ArticleBodyList: `
        ... on ArticleBodyList {
            listType
            listItem {
                text {
                    content
                }
            }
        }
    `,
  ArticleBodyFeaturedEntries: `
        ... on ArticleBodyFeaturedEntries {
            featuresModule {
                __typename
            }
        }
    `,
  ArticleBodyBreakerWithLinks: `
        ... on ArticleBodyBreakerWithLinks {
            headline {
                content
            }
            subhead {
                content
            }
            blockLinks {
                linkItems {
                    ... on LinkItemsLinkItem {
                        linkLabel
                        linkUrl
                    }
                }
            }
        }
    `,
  ArticleBodyStackedList: `
        ... on ArticleBodyStackedList {
            id
            heading: stackedListFieldTitle {
                content
            }
            list: stackedListFieldListItems {
                ... on StackedListFieldListItemsSuperTableBlockType {
                    title: slfListItemTitle {
                        content
                    }
                    body: slfListItemBody {
                        content
                    }
                    label: slfListItemLabel
                }
            }
        }
    `,
  ArticleBodyEmailSignUp: `
        ... on ArticleBodyEmailSignUp {
            id
            emailSignUpTitle
            emailSignUpBody {
                content
            }
            emailSignUpSuccessMessage {
                content
            }
        }
    `,
  ArticleBodyRailCardImage: `
        ... on ArticleBodyRailCardImage {
            railCardImageAsset {
                id
                url
                title
            }
        }
    `,
  ArticleBodyOpenTextRailCard: `
        ... on ArticleBodyOpenTextRailCard {
            otrcBackgroundImage {
                id
                url
                filename
            }
            otrcOpenText {
                content
            }
            otrcLink {
                ... on OtrcLinkSuperTableBlockType {
                    linktype
                    internalLink {
                        id
                        url
                        title
                    }
                    externalLink
                    assetLink {
                        id
                        url
                        title
                    }
                }
            }
        }
    `
};

const LRID = {
  wrapper: components => `
        leftRailIntroDetails {
            __typename
            ${components}
        }
    `,
  LeftRailIntroDetailsTlDr: `
        ... on LeftRailIntroDetailsTlDr {
            takeawaysPoints {
                takeawayPointItem
            }
            backgroundElement {
                hex
            }
            moduleTitle
        }
    `,
  LeftRailIntroDetailsAccolades: `
        ... on LeftRailIntroDetailsAccolades {
            accoladesItem {
                recognitionTitle
                awardName
                accoladesLinkType
                awardInternalLink {
                    uri
                }
                awardExternalLink
            }
        }
    `,
  LeftRailIntroDetailsLinks: `
        ... on LeftRailIntroDetailsLinks {
            blockTitle
            linkItem {
                linkLabel
                linkUrl
                linkSecondary
            }
        }
    `,
  LeftRailIntroDetailsAuthors: `
        ... on LeftRailIntroDetailsAuthors {
            authors {
                title
            }
        }
    `,
  LeftRailIntroDetailsParentAccountTout: `
        ... on LeftRailIntroDetailsParentAccountTout {
            parentAccountToutLink {
                uri
                title
            }
        }
    `,
  LeftRailIntroDetailsPartners: `
        ... on LeftRailIntroDetailsPartners {
            sinceLabel
        }
    `,
  LeftRailIntroDetailsEventDetails: `
        ... on LeftRailIntroDetailsEventDetails {
            whatLabel
            whereLabel
            linkText
            startDateAndTime
            endDateAndTime
            allDay
            timezone {
                title
            }
        }
    `,
  LeftRailIntroDetailsOpenWyswyg: `
        ... on LeftRailIntroDetailsOpenWyswyg {
            contentArea {
                content
            }
        }
    `,
  LeftRailIntroDetailsResourcesNew: `
        ... on LeftRailIntroDetailsResourcesNew {
          resourceTitle
          resourceitem {
              resourcesLinkType
              resourceName
              internalLink {
                  id
                  uri
              }
              externalLink
              assetLink {
                  id
                  title
                  filename
                  folder {
                      name
                  }
                  url
              }
          }
      }
    `,
  LeftRailIntroDetailsResources: `
        ... on LeftRailIntroDetailsResources {
            resourceItem {
                resourcesLinkType
                resourceName
                resourceTitle
                internalLink {
                    id
                    uri
                }
                externalLink
                assetLink {
                    id
                    title
                    filename
                    folder {
                        name
                    }
                    url
                }
            }
        }
    `
};

export { articleBody, LRID };
