import React, { useRef } from 'react';
import useReducedMotion from '../../hooks/useReducedMotion';
import useTimeout from '../../hooks/useTimeout';

import styles from './styles.scss';

const InteractiveTitle = props => {
  const { title } = props;
  const timeout = useTimeout();
  const h1Ref = useRef();
  const { isReducedMotionActive } = useReducedMotion();

  function wrapTitle() {
    const wrapTitle = title.split(' ');
    const text = [];

    for (let i = 0; i < wrapTitle.length; i++) {
      text[i] = `<span class=${styles.interactiveTitle__span} aria-hidden="true">
                            ${Array.from(Array(6))
                              .map(() => `<span data-text=${wrapTitle[i]}>${wrapTitle[i]}</span>`)
                              .join('')}
                        </span>`;
    }

    return text.join('');
  }

  function mouseEnterHandler() {
    h1Ref.current.classList.remove(styles.enter);
    timeout(() => {
      h1Ref.current.classList.add(styles.enter);
    }, 50);
  }

  function mouseEnterHandler() {
    h1Ref.current.classList.remove(styles.enter);
    timeout(() => {
      h1Ref.current.classList.add(styles.enter);
    }, 50);
  }

  return (
    <header className={styles.interactiveTitle}>
      <div className="wrapper">
        <h1
          ref={h1Ref}
          onMouseEnter={!isReducedMotionActive ? mouseEnterHandler : ''}
          dangerouslySetInnerHTML={{ __html: wrapTitle() }}
          aria-label={title}
        />
      </div>
    </header>
  );
};

export default InteractiveTitle;
