import React from 'react';

import styles from './styles.scss';

import { toNYDateString } from '../../helpers/date';

const PublishDate = ({ data, theme }) => {
  const { postDate } = data;

  const rawDate = new Date(postDate * 1000);
  const publishDate = toNYDateString(rawDate);

  return (
    <article
      className={`${styles.leftRailIntroDetails} ${
        theme === 'light' ? styles.leftRailIntroDetails__light : styles.leftRailIntroDetails__dark
      } ${styles.date} ${theme === 'light' ? styles.date__light : styles.date__dark}`}>
      <h4 dangerouslySetInnerHTML={{ __html: publishDate }} />
    </article>
  );
};

export default PublishDate;
