import React from 'react';
import classNames from 'classnames/bind';
import { stripParagraphTag } from 'helpers/text';
import { get } from 'helpers/utilities';

import styles from './styles.scss';

const cx = classNames.bind(styles);

const List = ({ data, theme }) => {
  const { listItem, listType } = data;

  if (!listItem || (listItem && listItem.length < 0)) return null;

  const numberedTypes = ['numbered', 'numberedDividers'];
  const isNumbered = numberedTypes.includes(listType);

  const listClassses = cx('list', {
    'list--dark': theme === 'dark',
    'article-list--bullet': !isNumbered,
    'with-divider': listType === 'numberedDividers'
  });

  return isNumbered ? (
    <ol className={listClassses} aria-label="Numbered List">
      {listItem.map((item, index) => {
        const itemText = get(item, 'text.content');

        return (
          itemText && (
            <li
              key={index}
              dangerouslySetInnerHTML={{ __html: stripParagraphTag(item.text.content) }}
            />
          )
        );
      })}
    </ol>
  ) : (
    <ul className={listClassses} aria-label="Bulleted List">
      {listItem.map((item, index) => {
        const itemText = get(item, 'text.content');

        return (
          itemText && (
            <li
              key={index}
              dangerouslySetInnerHTML={{ __html: stripParagraphTag(item.text.content) }}
            />
          )
        );
      })}
    </ul>
  );
};

export default List;
