import useEntryContent from './useEntryContent';
import articleQueries from '../queries/articles';

const useArticleContent = pathname => {
  const query = articleQueries.article;

  return useEntryContent(pathname, query);
};

export default useArticleContent;
