import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { get } from 'helpers/utilities';
import buildGTMData from 'helpers/buildGTMData';

// components
import Contact from 'components/Contact';
import AwardsModule from 'components/AwardsModule';
import ArticleBody from 'components/ArticleBody';
import CuratedPortfolioHeader from 'components/CuratedPortfolioHeader';
import CuratedCardGrid from 'components/CuratedCardGrid';
import CuratedPortfolioFetch from 'components/CuratedPortfolioFetch';
import CuratedPackageCards from 'components/CuratedPackageCards';
import Meta from 'components/Meta';

// helpers
import getPageData from 'helpers/getPageData';

// hooks
import useContact from '../../hooks/useContact';
import styles from './styles.scss';

const CuratedPortfolioBody = ({ data, active }) => {
  const entry = get(data, 'data.entry', {});
  const contactData = useContact(entry);
  const gtmData = buildGTMData(entry);

  return (
    <>
      <Meta metaDetails={{ noIndex: true }} active={active} gtm={gtmData} />
      <CuratedPortfolioHeader data={get(entry, 'curatedPortfolioHeader[0]')} />
      <ArticleBody
        data={get(entry, 'articleBody')}
        pageData={getPageData(data)}
        leftRailIntroDetails={get(entry, 'leftRailIntroDetails')}
        isCuratedPortfolio={true}
        theme="dark"
        noScrollTracker={true}
      />
      {get(entry, 'curatedPackage', []).map((curatedPackage, index) => (
        <CuratedPackageCards data={curatedPackage} key={index} />
      ))}
      <AwardsModule
        data={get(entry, 'awardsModule[0].awards[0]')}
        awardsModuleTitleClass={styles.curatedPortfolio__awardsModuleTitleClass}
      />
      <CuratedCardGrid data={get(entry, 'curatedCardGrid[0]')} />
      <Contact {...contactData} isMinified={false} />
    </>
  );
};

const CuratedPortfolio = ({ match, active }) => {
  const article = get(match, 'params.article');
  return (
    <CuratedPortfolioFetch
      url={`/pitch/${article}`}
      article={article}
      onSuccess={() => window.scrollTo({ top: 0 })}>
      {data => <CuratedPortfolioBody data={data} active={active} />}
    </CuratedPortfolioFetch>
  );
};

export default withRouter(CuratedPortfolio);
