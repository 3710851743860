const getPageData = data => {
  const keys = ['title', 'uri', 'url', 'postDate', '__typename', 'id'];
  const result = {};

  keys.forEach(key => (result[key] = data[key]));

  return result;
};

export default getPageData;
