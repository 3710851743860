import React, { Fragment } from 'react';
import { parseExternalLink } from 'helpers/text';

import styles from './styles.scss';

const Links = ({ data, theme }) => {
  const { blockTitle, linkItem } = data;
  return (
    <div
      className={`${styles.leftRailIntroDetails} ${
        theme === 'light' ? styles.leftRailIntroDetails__light : styles.leftRailIntroDetails__dark
      } ${styles.links} ${theme === 'light' ? styles.links__light : styles.links__dark}`}>
      <h2
        className={styles.leftRailTitle}
        dangerouslySetInnerHTML={{ __html: blockTitle || 'Links' }}
      />
      {linkItem.map((item, index) => (
        <Fragment key={index}>
          <a href={parseExternalLink(item.linkUrl)} title={item.linkLabel}>
            {item.linkLabel}
          </a>
          {item.linkSecondary ? <span>{item.linkSecondary}</span> : null}
        </Fragment>
      ))}
    </div>
  );
};

export default Links;
