import { useQuery } from '@apollo/react-hooks';
import { get } from 'helpers/utilities';
import articlesQuery from 'queries/articles';

const useAccountCardGridContent = articleContentData => {
  const accountCardGridQuery = articlesQuery[get(articleContentData, '__typename')].cardGrid(
    get(articleContentData, 'uri').split('/')[1]
  );
  const { loading, error, data } = useQuery(accountCardGridQuery);

  if (loading || error) {
    return null;
  }

  const accountArticles = get(data, 'entry.accountCardGrid.collectionEntries', null);

  return accountArticles;
};

export default useAccountCardGridContent;
