import React from 'react';
import classNames from 'classnames/bind';
import { removeInfoSlug } from '../../helpers/text';
import styles from './styles.scss';
import Icon from '../Icon';
import { isEmpty, get } from '../../helpers/utilities';

const cx = classNames.bind(styles);

const OpenTextRailCard = ({ data, oddEven, theme }) => {
  const { otrcBackgroundImage = [], otrcOpenText = {}, otrcLink = [] } = data || {};
  let url;
  let link;

  if (otrcLink && otrcLink.length > 0) {
    [link] = otrcLink;

    if (link.linktype === 'internalLink') {
      url = get(link, 'internalLink[0].url');
    } else if (link.linktype === 'externalLink') {
      url = get(link, 'externalLink');
    } else {
      url = get(link, 'assetLink[0].url');
    }
  }

  const openTextRailCardClass = cx(
    'openTextRailCard',
    {
      'openTextRailCard--odd': oddEven,
      'openTextRailCard--even': !oddEven
    },
    `openTextRailCard--${theme}`
  );

  const openTextRailCardContainerClass = cx('openTextRailCard__container', {
    'openTextRailCard__container--containerOdd': oddEven,
    'openTextRailCard__container--containerEven': !oddEven
  });

  return (
    <div className={openTextRailCardClass}>
      <div
        className={openTextRailCardContainerClass}
        style={{
          background: !isEmpty(otrcBackgroundImage)
            ? `url(${otrcBackgroundImage[0].url}) center center / cover no-repeat`
            : null
        }}>
        <div
          className={styles.openTextRailCard__content}
          dangerouslySetInnerHTML={{
            __html: removeInfoSlug(otrcOpenText ? otrcOpenText.content : '')
          }}
        />
        {url && (
          <a
            className={styles.openTextRailCard__arrow}
            aria-label={`Open Text Rail Card ${link.linktype}`}
            href={url && url}>
            <Icon name="arrowRight" stroke="white" fill="none" />
          </a>
        )}
      </div>
    </div>
  );
};

export default OpenTextRailCard;
