import React, { useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// components
import LoginModal from 'components/LoginModal';
import NotFound from 'components/NotFound';
import LoadingDots from 'components/LoadingDots';

// hooks
import { getCuratedPortfolioToken, setCuratedPortfolioToken } from 'helpers/localStorage';

// helpers
import { post } from 'helpers/api';
import { get } from 'helpers/utilities';
import useFetch from '../../hooks/useFetch';

const CuratedPortfolioFetch = props => {
  const { url, children, onSuccess, onError, article } = props;
  const history = useHistory();
  const [loginError, setLoginError] = useState('');
  const [loginLoading, setLoginLoading] = useState(false);
  const location = useLocation();

  const [options, setOptions] = useState({
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      auth: getCuratedPortfolioToken(article)
    }
  });

  const login = useCallback(
    (e, formData) => {
      e.preventDefault();
      setLoginLoading(true);
      post(`/pitch/${article}/login${location.search}`)(formData)
        .then(response => {
          const { data, error } = response;
          if (error) {
            setLoginError(get(error, 'message'));
            setLoginLoading(false);
          } else {
            setCuratedPortfolioToken(article, data);
            setOptions({
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                auth: getCuratedPortfolioToken(article)
              }
            });
          }
        })
        .catch(err => {
          setLoginError(get(err.error, 'message'));
          setLoginLoading(false);
        });
    },
    [article, location.search]
  );

  const {
    data,
    loading,
    error: err
  } = useFetch({
    url: `${url}${location.search}`,
    options,
    onSuccess,
    onError
  });
  if (loading) return <LoadingDots style={{ position: 'absolute', minHeight: '100vh' }} />;
  if (data) return children && children(data);
  if (err.error.name === 'UNAVAILABLE_RESOURCE') return <NotFound />;
  if (err.error.name === 'INVALID_CREDENTIALS')
    return (
      <LoginModal
        show={true}
        error={!loginLoading && loginError}
        loading={loginLoading}
        onClickSubmit={(e, formData) => login(e, formData)}
        onClickCancel={() => history.push('/')}
        onClickClose={() => history.push('/')}
      />
    );
};

export default CuratedPortfolioFetch;
