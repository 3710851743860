import React, { useRef, useEffect, useCallback } from 'react';
import { removeInfoSlug } from 'helpers/text';
import { animateScrollTo } from 'helpers/scroll';

import useNavigation from '../../hooks/useNavigation';
import useTimeout from '../../hooks/useTimeout';

import styles from './styles.scss';

const OpenWyswyg = props => {
  const { data, className } = props;
  const { setNavVisible, setNavToggling } = useNavigation();
  const timeout = useTimeout();

  // Refs
  const highlightsRef = useRef(null);
  const IntersectionObserverRef = useRef(null);
  const openWyswygRef = useRef();
  const touHeadingsRef = useRef([]);

  const wrapHighlightedText = useCallback(() => {
    Array.from(highlightsRef.current).forEach(highlight => {
      const $highlight = highlight;
      const text = $highlight.innerHTML;
      const wrappedText = `<span>${text}</span>`;
      $highlight.innerHTML = wrappedText;
    });
  }, []);

  const scrollToHashedContent = useCallback(hash => {
    setNavToggling(false);
    setNavVisible(false);
    const destination = document.getElementById(hash.slice(1));
    if (!destination) return;
    const bounding = destination.getBoundingClientRect();
    const offset = 10;
    const scrollDestination = window.scrollY + bounding.top;
    animateScrollTo(scrollDestination - offset, () => {
      setNavVisible(false);
      timeout(() => setNavToggling(true), 100);
    });
  }, []);

  // componentDidMount
  useEffect(() => {
    if (!openWyswygRef.current) return;
    const openWysywygContainer = openWyswygRef.current;
    highlightsRef.current = openWysywygContainer.querySelectorAll('.highlight');
    wrapHighlightedText();
    let currentObserver;

    // Terms of Use Headings
    touHeadingsRef.current = openWysywygContainer.querySelectorAll('.tou-heading');

    if (touHeadingsRef.current.length) {
      Array.from(touHeadingsRef.current).forEach(el => {
        el.classList.add(styles['transformed-tou-heading']);
      });
    }

    if (highlightsRef.current.length) {
      const config = {
        root: null,
        margin: '0px',
        threshold: [0, 1]
      };

      const callback = (entries, observer) => {
        const [entry] = entries;

        if (entry.intersectionRatio > 0) {
          entry.target.classList.add(styles['openWyswyg--highlight']);
          observer.unobserve(entry.target);
        }
      };

      if (IntersectionObserverRef.current) {
        IntersectionObserverRef.current.disconnect();
      }

      IntersectionObserverRef.current = new IntersectionObserver(callback, config);
      currentObserver = IntersectionObserverRef.current;

      Array.from(highlightsRef.current).forEach(highlight => {
        IntersectionObserverRef.current.observe(highlight);
      });
    }

    // disable hash anchoring
    const hashedAnchors = openWysywygContainer.querySelectorAll("a[href^='#']");
    hashedAnchors.forEach(anchor => {
      const hash = anchor.getAttribute('href');
      anchor.onclick = e => {
        e.preventDefault();
        if (hash) scrollToHashedContent(hash);
      };
    });

    // add table container and set cellpadding to 0
    const tables = openWysywygContainer.querySelectorAll('table');
    [...tables].forEach(item => {
      item.setAttribute('cellspacing', '0');
      const outer = item.outerHTML;
      const newOuter = `<div class="table" style="overflow:auto">${outer}</div>`;
      item.outerHTML = newOuter;
    });

    // Add custom class name to eyebrow
    const eyebrowList = [...openWysywygContainer.querySelectorAll('.eyebrow-styled')];
    eyebrowList.forEach(eyebrow => eyebrow.classList.add(styles.openWyswyg__eyebrowStyled));

    // Cleanup
    return () => {
      if (highlightsRef.current && highlightsRef.current.current) {
        currentObserver.disconnect();
      }
    };
  }, []);

  return (
    <div
      ref={openWyswygRef}
      className={`${className} ${styles.openWyswyg}`}
      dangerouslySetInnerHTML={{ __html: removeInfoSlug(data.text.content) }}
    />
  );
};

export default OpenWyswyg;
