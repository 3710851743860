import { useEffect, useState } from 'react';

// helpers
import { handleResponse } from 'helpers/api';

const useFetch = ({ url, options, onSuccess = () => {}, onError = () => {} }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    window
      .fetch(url, options)
      .then(handleResponse)
      .then(response => {
        // cnt route specific
        // fetch is error when error has value
        // this is to cope up with the basic auth
        // because if basic auth detects an error status
        // it will prompt another request field
        if (response.error) {
          setError(response.error);
          setData(null);
          onError(response.error);
        } else {
          setError(null);
          setData(response.data);
          onSuccess(response.data);
        }
      })
      .catch(_error => {
        setError(_error);
        setData(null);
        onError(_error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [url, JSON.stringify(options)]);

  return { loading, data, error };
};

export default useFetch;
