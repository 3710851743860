import { get } from 'helpers/utilities';

export const parseGeneralContactModule = data => {
  const contactTitle = get(data, '[0].contactTitle', {});
  const contactOptions = [
    get(data, '[0].findUs[0]'),
    get(data, '[0].workWithUs[0]'),
    get(data, '[0].press[0]'),
    get(data, '[0].careers[0]')
  ];
  return {
    contactTitle,
    contactOptions: contactOptions.filter(c => c)
  };
};

export const parseSpecializedContactModule = data => {
  const contactTitle = get(data, '[0].contactTitle', {});
  const contactOptions = [get(data, '[0].specialContact[0]'), get(data, '[0].generalContact[0]')];
  return {
    contactTitle,
    contactOptions: contactOptions.filter(c => c)
  };
};

const contactModule = `
    contactModuleField {
        ... on ContactModulesSpecialContact {
            specializedContactModule {
                ... on SpecializedContactModuleSpecializedContact {
                    contactTitle {
                        content
                    }
                    specialContact {
                        contactTitle
                        contactEmail
                        contactNumber
                    }
                    generalContact {
                        contactTitle
                        contactEmail
                        contactNumber
                    }
                }
            }
        }
        ... on ContactModulesGeneralContact {
            generalContactModule {
                ... on GeneralContactModuleGeneralContact {
                    contactTitle {
                        content
                    }
                    findUs {
                        contactTitle
                        contactAddress {
                            content
                        }
                        contactLink {
                            uri
                            title
                        }
                        contactCtaTitle
                        contactComponentLink
                    }
                    workWithUs {
                        contactTitle
                        contactEmail
                    }
                    press {
                        contactTitle
                        contactEmail
                    }
                    careers {
                        contactTitle
                        contactEmail
                        contactLink {
                            uri
                            title
                        }
                        contactCtaTitle
                    }
                }
            }
        }
    }
`;

export default contactModule;
