import React, { useRef, useEffect, useCallback } from 'react';
import { get } from 'helpers/utilities';
import { listenOnWheel, disableBodyScroll, enableBodyScroll } from 'helpers/scroll';
import { getThrottledRAF } from 'helpers/layout';
import { clamp } from 'helpers/fp';
import EventHandler from 'helpers/EventHandler';
import { EVENT } from 'config/constants';
import Overlay from 'components/Overlay';
import useAccessibility from '../../hooks/useAccessibility';
import useBreakpoint from '../../hooks/useBreakpoint';
import useReducedMotion from '../../hooks/useReducedMotion';

import styles from './style.scss';

const getImageContainerHeight = breakpoint => {
  const containerHeights = {
    mobile: 'auto',
    tablet: 'auto',
    smallDesktop: '429px',
    largeDesktop: '577px'
  };
  return containerHeights[breakpoint];
};

const getScollImageContainerScrollDimension = scrollJackImageContainerRef => {
  const scrollJackImageContainer = scrollJackImageContainerRef.current;

  const minScroll = 0;
  const maxScroll = scrollJackImageContainer.scrollHeight - scrollJackImageContainer.clientHeight;

  return { minScroll, maxScroll };
};

const toggleBodyBackground = toggle => {
  if (toggle) {
    Object.assign(document.body.style, { background: 'white' });
  } else {
    Object.assign(document.body.style, { background: 'black' });
  }
};

const getScrollJackDimension = scrollJackRef => {
  const scrollJack = scrollJackRef.current;
  const boundingY = scrollJack.getBoundingClientRect().top;
  const scrollY = window.scrollY || window.pageYOffset;

  const midpoint = Math.ceil(
    boundingY + scrollJack.clientHeight / 2 - document.documentElement.clientHeight / 2 + scrollY
  );

  return { midpoint };
};

const ScrollJackDesktop = props => {
  const {
    data: { asset, caption }
  } = props;
  const { resetAccessibility, isClientPassingThroughLinks } = useAccessibility();
  const { isReducedMotionActive } = useReducedMotion();
  const breakpoint = useBreakpoint();
  const scrollJackRef = useRef();
  const scrollJackImageContainerRef = useRef();
  const scrollTop = useRef(0);
  const isScrollDisabled = useRef(false);

  const resetScrollJack = useCallback(bp => {
    Object.assign(scrollJackImageContainerRef.current.style, {
      height: getImageContainerHeight(bp)
    });
    enableBodyScroll();
    isScrollDisabled.current = false;
    scrollTop.current = 0;
  }, []);

  useEffect(() => {
    scrollJackImageContainerRef.current.scrollTop = 0;
    const scrollEvent = EventHandler.subscribe(EVENT.SCROLL, () => {
      const scrollY = window.scrollY || window.pageYOffset;

      if (
        breakpoint === 'tablet' ||
        breakpoint === 'mobile' ||
        isReducedMotionActive ||
        !(
          breakpoint !== 'smallDesktop' ||
          breakpoint !== 'largeDesktop' ||
          breakpoint !== 'maxpoint'
        )
      ) {
        return;
      }

      if (isClientPassingThroughLinks) return;

      const { minScroll, maxScroll } = getScollImageContainerScrollDimension(
        scrollJackImageContainerRef
      );
      const { midpoint } = getScrollJackDimension(scrollJackRef);

      if (
        (scrollY >= midpoint && scrollTop.current === minScroll) ||
        (scrollY <= midpoint && scrollTop.current === maxScroll)
      ) {
        disableBodyScroll();
        isScrollDisabled.current = true;
        toggleBodyBackground(isScrollDisabled.current);
        window.scrollTo(0, midpoint);
      }
    });
    const throttledRAF = getThrottledRAF();
    const unListenOnWheel = listenOnWheel({ maxDeltaTouch: 8 }, ({ deltaY, direction }) =>
      throttledRAF(() => {
        resetAccessibility();
        if (breakpoint === 'tablet' || breakpoint === 'mobile' || isReducedMotionActive) return;
        if (!isScrollDisabled.current) return;

        const { minScroll, maxScroll } = getScollImageContainerScrollDimension(
          scrollJackImageContainerRef
        );
        const { midpoint } = getScrollJackDimension(scrollJackRef);

        scrollTop.current += deltaY;
        scrollTop.current = clamp(0, maxScroll)(scrollTop.current);

        const scrollJackImageContainer = scrollJackImageContainerRef.current;
        scrollJackImageContainer.scrollTop = scrollTop.current;

        if ((window.scrollY || window.pageYOffset) !== midpoint) {
          window.scrollTo(0, midpoint);
        }

        if (scrollTop.current === minScroll || scrollTop.current === maxScroll) {
          enableBodyScroll();
          isScrollDisabled.current = false;
          toggleBodyBackground(isScrollDisabled.current);
          if (direction === 'up') window.scrollTo(0, midpoint - 10);
          else window.scrollTo(0, midpoint + 10);
        }
      })
    );

    return () => {
      EventHandler.unsubscribe(scrollEvent);
      unListenOnWheel();
    };
  }, [breakpoint]);

  useEffect(() => resetScrollJack(breakpoint), [breakpoint]);

  if (isClientPassingThroughLinks) {
    resetScrollJack(breakpoint);
  }

  return (
    <section data-full-width ref={scrollJackRef}>
      <section className="wrapper">
        <div className={styles.scroll_jack}>
          <div className={styles.scroll_jack__image_container} ref={scrollJackImageContainerRef}>
            <img src={get(asset, '[0].url')} alt="Window" />
          </div>
          <div
            className={styles.scroll_jack__caption}
            dangerouslySetInnerHTML={{ __html: get(caption, 'content') }}
          />
          <Overlay color="transparent" opacity="1" />
        </div>
      </section>
    </section>
  );
};

export default ScrollJackDesktop;
