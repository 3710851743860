import React from 'react';
import { get } from '../../helpers/utilities';
import styles from './styles.scss';

const StackedList = ({ data = {} }) => {
  const { heading, list } = data;
  const headingText = get(heading, 'content');

  return (
    <section className={styles.stackedList} data-full-width="true">
      {headingText && (
        <div
          className={styles.stackedList__heading}
          dangerouslySetInnerHTML={{ __html: headingText }}
        />
      )}
      <ul className={styles.stackedList__listContainer}>
        {list &&
          list.map((item, index) => {
            const counter = `${index + 1}`.padStart(2, '0');
            const title = get(item, 'title.content');
            const label = get(item, 'label');
            const body = get(item, 'body.content');

            return (
              <li className={styles.stackedList__listItem} key={index}>
                <p className={styles.stackedList__listItem__counter}>{counter}</p>
                <div className={styles.stackedList__listItem__content}>
                  {title && (
                    <div
                      className={styles.stackedList__listItem__title}
                      dangerouslySetInnerHTML={{ __html: title }}
                    />
                  )}
                  {label && <p className={styles.stackedList__listItem__label}>{label}</p>}
                  {body && (
                    <div
                      className={styles.stackedList__listItem__description}
                      dangerouslySetInnerHTML={{ __html: body }}
                    />
                  )}
                </div>
              </li>
            );
          })}
      </ul>
    </section>
  );
};

export default StackedList;
