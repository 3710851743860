import React, { useRef, useEffect, useCallback } from 'react';
import { fromUnixTime, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import classNames from 'classnames/bind';

// components
import Image from 'components/Image';
import Video from 'components/Video';
import { withNavigationThemeSetter } from 'components/NavigationThemeSetter';
import { ResizeObserver } from 'resize-observer';

// helpers
import EventHandler from 'helpers/EventHandler';
import { stripParagraphTag } from 'helpers/text';
import { getThrottledRAF } from 'helpers/layout';
import { find, get } from 'helpers/utilities';
import { EVENT } from 'config/constants';
import { getArticleHeaderVideo } from 'queries/articleHeader';

// hooks
import useNavigation from '../../hooks/useNavigation';
import useBreakpoint from '../../hooks/useBreakpoint';

import styles from './styles.scss';

const cx = classNames.bind(styles);

const imgixParams = {
  auto: 'compress,enhance,format',
  dpr: 3
};

const imageSizes = {
  largeDesktop: 2000,
  smallDesktop: 1440,
  tablet: 1248,
  mobile: 960
};

const ArticleHeaderMedia = withNavigationThemeSetter({ navTheme: 'header' })(
  ({
    mediaType,
    videoRef,
    image,
    video,
    mediaRef,
    mediaWrapperRef,
    headerRef,
    ar16by9,
    onVideoLoad,
    articleHeaderRef,
    articleHeaderClass,
    backgroundImageRef
  }) => {
    const style = !video
      ? {
          backgroundSize: ar16by9 ? 'contain' : '100%',
          transform: 'scale(1.1)'
        }
      : {};

    const articleHeaderFullWidth = cx('articleHeader', {
      'articleHeader--vid-full-height': !image
    });

    return (
      <section
        className={`${articleHeaderClass} ${styles.articleHeader__sticky} articleHeader ${articleHeaderFullWidth}`}
        ref={articleHeaderRef}>
        <div
          className={`${styles.articleHeader__media} ${
            !ar16by9 ? styles.articleHeader__media_notAr : ''
          }`}
          ref={mediaRef}>
          {(image && image.url) || (video && video.url) ? (
            <div ref={mediaWrapperRef} className={styles.articleHeader__media__wrapper}>
              <Image
                className="lazyload"
                src={image ? get(image, 'url', '') : ''}
                imgixParams={imgixParams}
                sizes={imageSizes}
                style={style}
                ref={backgroundImageRef}
                attributeConfig={{
                  src: 'data-src',
                  srcSet: 'data-srcset'
                }}
              />
              {mediaType === 'video' ? (
                <div
                  className={`${styles['articleHeader__video-overlay']}  ${
                    styles['articleHeader__video-overlay']
                  }${ar16by9 ? '--16by9' : ''} ${styles['articleHeader__video-overlay--hidden']} `}>
                  <Video
                    autoPlay={true}
                    loop={true}
                    controls={false}
                    muted={true}
                    ref={videoRef}
                    src={get(video, 'url', '')}
                    togglePlay={false}
                    headerRef={headerRef}
                    onProgress={onVideoLoad}
                    ar16by9={ar16by9}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </section>
    );
  }
);

const ArticleHeaderTitle = withNavigationThemeSetter({ navTheme: 'black' })(
  ({ currentMedia, data, isSaysALot, __typename, postDate, headerRef }) => {
    const eyebrow = get(data, 'eyebrow');

    return (
      <section
        ref={headerRef}
        className={`${styles.articleHeader__container} ${
          !get(currentMedia, '[0]') ? styles.articleHeader__no_asset : ''
        }`}>
        <div className={styles.articleHeader__content}>
          {(data && data.eyebrow) || isSaysALot ? (
            <span className={styles.articleHeader__meta}>
              <span className={styles.articleHeader__label}>
                {isSaysALot ? __typename : eyebrow}
              </span>
              {isSaysALot && isSaysALot !== 'Event' ? (
                <>
                  <span className={styles.articleHeader__separator}> | </span>
                  <span>
                    {format(
                      utcToZonedTime(fromUnixTime(postDate), 'America/New_York'),
                      'MMMM do yyyy'
                    )}
                  </span>
                </>
              ) : null}
            </span>
          ) : null}
          <h1
            className={`${styles.articleHeader__title} squiggly`}
            dangerouslySetInnerHTML={{
              __html:
                data && data.articleTitle ? stripParagraphTag(data.articleTitle.content) : null
            }}
          />
        </div>
      </section>
    );
  }
);

const ArticleHeader = props => {
  const { data, pageData } = props;
  const { ar16by9 } = data;
  const { setNavGradient } = useNavigation();
  const breakpoint = useBreakpoint();
  const headerTitleRef = useRef();

  // References
  const mediaRef = useRef();
  const mediaWrapperRef = useRef();
  const videoRef = useRef();
  const articleHeaderRef = useRef();
  const backgroundImage = useRef();
  const bounding = useRef();

  // Variables
  const initScale = useRef(1.1);

  const setVideoLoaded = useCallback(event => {
    if (event.loaded >= 0.1) {
      // Not a reactive way to remove the overlay class articleHeader__video-overlay--hidden.
      const videoOverlayElem = document.querySelector(`.${styles['articleHeader__video-overlay']}`);

      if (videoOverlayElem) {
        videoOverlayElem.classList.remove(`${styles['articleHeader__video-overlay--hidden']}`);
      }
    }
  }, []);

  useEffect(() => {
    if (!backgroundImage.current) {
      setNavGradient(false);
      return;
    }

    const { imageBackground, videoBackground } = data;

    bounding.current = articleHeaderRef.current.getBoundingClientRect();

    const scrollHandler = (_, e) => {
      if (!backgroundImage.current || !data.imageBackground.length) {
        return;
      }

      // Image scaling computation
      if (backgroundImage.current && !videoBackground) {
        const ratio =
          initScale.current - (e.scrollY / bounding.current.height) * (initScale.current - 1);
        backgroundImage.current.style.transform = `scale(${ratio})`;
      }
    };

    const throttledRAF = getThrottledRAF();

    let scrollEvent;
    if (!ar16by9 && imageBackground && imageBackground.length) {
      // Avoid jumping on initial load
      scrollHandler(null, { scrollY: window.scrollY || window.pageYOffset });

      scrollEvent = EventHandler.subscribe(EVENT.SCROLL, (_, e) => {
        throttledRAF(() => scrollHandler(_, e));
      });
    }

    return () => scrollEvent && EventHandler.unsubscribe(scrollEvent);
  }, []);

  useEffect(() => {
    const articleHeaderElement = articleHeaderRef.current;
    let resizeObserver = null;

    if (articleHeaderElement) {
      resizeObserver = new ResizeObserver(() => {
        if (articleHeaderElement) bounding.current = articleHeaderElement.getBoundingClientRect();
      });

      resizeObserver.observe(articleHeaderElement);
    }

    return () => resizeObserver && resizeObserver.disconnect();
  }, []);

  const { leftRailIntroDetails, postDate, __typename } = pageData;
  const leftRailIntroData = leftRailIntroDetails.find(i => i.startDateAndTime);

  const isSaysALot = find(['Event', 'Press', 'News'], type => type === __typename);
  const currentVideo = getArticleHeaderVideo(data, breakpoint);
  const currentImage = get(data, 'imageBackground');
  const currentMediaType = currentImage && currentImage[0] && !currentVideo ? 'image' : 'video';
  const articleMedia = currentImage && currentImage.length ? currentImage[0] : currentVideo;
  const articleHeaderClass = cx('articleHeader', {
    'articleHeader--image': currentMediaType === 'image',
    'articleHeader--video': currentMediaType === 'video'
  });

  return (
    <>
      {(currentImage.length || currentVideo) && (
        <ArticleHeaderMedia
          mediaRef={mediaRef}
          mediaWrapperRef={mediaWrapperRef}
          videoRef={videoRef}
          headerRef={headerTitleRef}
          media={articleMedia}
          image={currentImage && currentImage.length ? currentImage[0] : null}
          video={currentVideo}
          backgroundImageRef={backgroundImage}
          mediaType={currentMediaType}
          ar16by9={ar16by9}
          onVideoLoad={e => setVideoLoaded(e)}
          articleHeaderRef={articleHeaderRef}
          articleHeaderClass={articleHeaderClass}
        />
      )}
      <ArticleHeaderTitle
        currentMedia={currentImage || currentVideo}
        data={data}
        isSaysALot={isSaysALot}
        __typename={__typename}
        postDate={leftRailIntroData?.startDateAndTime || postDate}
        headerRef={headerTitleRef}
      />
    </>
  );
};

export default ArticleHeader;
