import React from 'react';

import styles from './styles.scss';

const SliderButton = ({ onClick, direction, className }) => (
  <button
    type="button"
    onClick={onClick}
    className={`
            ${styles.sliderButton}
            ${direction === 'right' ? styles.sliderButton_right : styles.sliderButton_left}
            ${className}
        `}>
    <span />
  </button>
);

export default SliderButton;
