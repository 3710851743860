import { gql } from 'apollo-boost';
import metaDetails from './metaDetails';
import contactModule from './contactModule';
import articleHeaders from './articleHeader';
import { features } from './twm_components';
import cardQueries from './cardQueries';

const typenames = `
    articleBody {
        __typename
    }
    leftRailIntroDetails {
        __typename
        ... on LeftRailIntroDetailsEventDetails {
            startDateAndTime
        }
    }
`;

const articles = {
  article: `
        id
        ... on SinglePage {
            ${articleHeaders.caseStudy}
            ${typenames}
            ${metaDetails}
            ${contactModule}
            associatedYear {
                title
            }
            accountParent {
                title
            }
            taxonomy {
                title
            }
            outputCategory {
                title
            }
            companyCategory {
                title
            }
            withRelatedWorksSinglePage
            withEmailSignup
            slug
        }
        ... on CaseStudiesCaseStudy {
            ${articleHeaders.caseStudy}
            ${typenames}
            ${metaDetails}
            associatedYear {
                title
            }
            accountParent {
                title
            }
            taxonomy {
                title
            }
            outputCategory {
                title
            }
            companyCategory {
                title
            }
            withRelatedWorksArticlePage
            withEmailSignup
        }
        ... on AccountsAccount {
            ${articleHeaders.account}
            ${typenames}
            ${metaDetails}
            accountCardGrid {
                categoryId
            }
            awardsModule {
                __typename
            }
            taxonomy {
                title
            }
        }
        ... on ThoughtLeadership {
            ${articleHeaders.thoughtLeadership}
            ${typenames}
            ${metaDetails}
            associatedYear {
                title
            }
            accountParent {
                title
            }
            taxonomy {
                title
            }
            withRelatedWorksArticlePage
            withEmailSignup
        }
        ... on CuratedWorkCuration {
            ${articleHeaders.curatedWork}
            ${typenames}
            ${metaDetails}
            ${contactModule}
            associatedYear {
                title
            }
            accountParent {
                title
            }
            taxonomy {
                title
            }
            withRelatedWorksArticlePage
            withEmailSignup
        }
        ... on Event {
            ${articleHeaders.event}
            ${typenames}
            ${metaDetails}
            accountParent {
                title
            }
            withRelatedWorksArticlePage
            withEmailSignup
            awardsModule {
                ${features.awards}
            }
        }
        ... on News {
            ${articleHeaders.news}
            ${typenames}
            ${metaDetails}
            accountParent {
                title
            }
            withRelatedWorksArticlePage
            withEmailSignup
        }
        ... on Press {
            ${articleHeaders.press}
            ${typenames}
            ${metaDetails}
            accountParent {
                title
            }
            outputCategory {
                title
            }
            companyCategory {
                title
            }
            withRelatedWorksArticlePage
            withEmailSignup
        }
        ... on Services {
            ${articleHeaders.service}
            ${typenames}
            ${metaDetails}
            associatedYear {
                title
            }
            accountParent {
                title
            }
            taxonomy {
                title
            }
            outputCategory {
                title
            }
            companyCategory {
                title
            }
        }
    `,
  SinglePage: {
    main: `
            id
            ... on SinglePage {
                ${articleHeaders.caseStudy}
                ${typenames}
                ${metaDetails}
                ${contactModule}
            }
        `,
    component: (uri, query) => gql`
            query {
                entry (uri: "${uri}") {
                    id
                    ... on SinglePage {
                        ${query}
                    }
                }
            }
        `,
    spFeatures: slug => query =>
      gql`
        query filtersQuery {
            entry (slug: "${slug}") {
                ... on SinglePage {
                    ${query}
                }
            }
        }
    `
  },
  CaseStudiesCaseStudy: {
    main: `
            id
            ... on CaseStudiesCaseStudy {
                ${articleHeaders.caseStudy}
                ${typenames}
                ${metaDetails}
            }
        `,
    component: (uri, query) => gql`
            query {
                entry (uri: "${uri}") {
                    id
                    ... on CaseStudiesCaseStudy {
                        ${query}
                    }
                }
            }
        `
  },
  AccountsAccount: {
    main: `
            id
            ... on AccountsAccount {
                ${articleHeaders.account}
                ${typenames}
                ${metaDetails}
                accountCardGrid {
                    categoryId
                }
                awardsModule {
                    ${features.awards}
                }
            }
        `,
    component: (uri, query) => gql`
            query {
                entry (uri: "${uri}") {
                    id
                    ... on AccountsAccount {
                        ${query}
                    }
                }
            }
        `,
    cardGrid: slug => gql`
            query {
                entry(section: accounts, slug: "${slug}") {
                    ... on AccountsAccount {
                        accountCardGrid {
                            collectionEntries {
                                id
                                uri
                                title
                                postDate
                                ${cardQueries.article}
                                ${cardQueries.project}
                                ${cardQueries.curatedWork}
                                ${cardQueries.event}
                                ${cardQueries.press}
                                ${cardQueries.news}
                            }
                        }
                    }
                }
            }
        `
  },
  ThoughtLeadership: {
    main: `
            id
            ... on ThoughtLeadership {
                ${articleHeaders.thoughtLeadership}
                ${typenames}
                ${metaDetails}
            }
        `,
    component: (uri, query) => gql`
            query {
                entry (uri: "${uri}") {
                    id
                    ... on ThoughtLeadership {
                        ${query}
                    }
                }
            }
        `
  },
  CuratedWorkCuration: {
    main: `
            id
            ... on CuratedWorkCuration {
                ${articleHeaders.curatedWork}
                ${typenames}
                ${metaDetails}
                ${contactModule}
            }
        `,
    component: (uri, query) => gql`
            query {
                entry (uri: "${uri}") {
                    id
                    ... on CuratedWorkCuration {
                        ${query}
                        ${contactModule}
                    }
                }
            }
        `
  },
  Event: {
    main: `
            ... on Event {
                ${articleHeaders.event}
                ${typenames}
                ${metaDetails}
            }
        `,
    component: (uri, query) => gql`
            query {
                entry (uri: "${uri}") {
                    id
                    ... on Event {
                        ${query}
                    }
                }
            }
        `
  },
  News: {
    main: `
            ... on News {
                ${articleHeaders.news}
                ${typenames}
                ${metaDetails}
            }
        `,
    component: (uri, query) => gql`
            query {
                entry (uri: "${uri}") {
                    id
                    ... on News {
                        ${query}
                    }
                }
            }
        `
  },
  Press: {
    main: `
            ... on Press {
                ${articleHeaders.press}
                ${typenames}
                ${metaDetails}
            }
        `,
    component: (uri, query) => gql`
            query {
                entry (uri: "${uri}") {
                    id
                    ... on Press {
                        ${query}
                    }
                }
            }
        `
  },
  CuratedPortfolio: {
    component: (uri, query) => gql`
            query {
                entry (uri: "${uri}") {
                    id
                    ... on CuratedPortfolio {
                        ${query}
                    }
                }
            }
        `
  },
  Services: {
    component: (uri, query) => gql`
            query {
                entry (uri: "${uri}") {
                    id
                    ... on Services {
                        ${query}
                    }
                }
            }
        `
  }
};

export default articles;
