import React from 'react';
import { get } from 'helpers/utilities';
import { stripParagraphTag } from 'helpers/text';

import styles from './styles.scss';

const PullQuote = ({ data }) => {
  const { blurb, pullQuoteSource, description } = data;
  return (
    <div className={`toolkit ${styles.pullQuote}`}>
      <div className={styles.pullQuote__container}>
        <blockquote
          className={styles.pullQuote__text}
          dangerouslySetInnerHTML={{ __html: stripParagraphTag(get(blurb, 'content')) }}
        />
        <span className={styles.pullQuote__meta}>
          <span className={styles.pullQuote__source}>{pullQuoteSource || null}</span>
          {description ? <span> | </span> : ''}
          <span className={styles.pullQuote__description}>{description || null}</span>
        </span>
      </div>
    </div>
  );
};

export default PullQuote;
