import React, { Fragment } from 'react';
import OpenWyswyg from 'components/OpenWyswyg';

import styles from './styles.scss';

const MetricsRailCard = ({ data, className, theme }) => {
  const { statTitle, statNumber, text } = data;

  return (
    <>
      <article
        className={`metricsRailCard ${styles.metricsRailCard} ${styles.metricsRailCard}--${theme} ${className}`}>
        <h2
          className={styles.metricsRailCard__title}
          dangerouslySetInnerHTML={{ __html: statTitle }}
        />
        <h3
          className={styles.metricsRailCard__number}
          dangerouslySetInnerHTML={{ __html: statNumber.toString() }}
        />
      </article>
      {text ? <OpenWyswyg data={data} /> : null}
    </>
  );
};

export default MetricsRailCard;
