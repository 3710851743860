import AlternateFlex from 'components/AlternateFlex';
import Carousel from 'components/Carousel';
import InlinePortraitImage from 'components/InlinePortraitImage';
import List from 'components/List';
import MediaEmbed from 'components/MediaEmbed';
import MetricsBreaker from 'components/MetricsBreaker';
import MetricsRailCard from 'components/MetricsRailCard';
import OpenWyswyg from 'components/OpenWyswyg';
import PullQuote from 'components/PullQuote';
import RelatedRailCard from 'components/RelatedRailCard';
import ProjectBreaker from 'components/ProjectBreaker';
import ScrollJackDesktop from 'components/ScrollJackDesktop';
import ZoomingImageBreaker from 'components/ZoomingImageBreaker';
import ArticleBreakerWithLinks from 'components/ArticleBreakerWithLinks';
import StackedList from 'components/StackedList';
import ImageRailCard from 'components/ImageRailCard';
import OpenTextRailCard from 'components/OpenTextRailCard';
import styles from './styles.scss';

export default {
  ArticleBodyOpenWyswyg: {
    Component: OpenWyswyg,
    staticProps: {
      className: styles.openWyswyg
    }
  },
  InfoBodyInfoWysiwyg: {
    Component: OpenWyswyg
  },
  OpenWysiwyg: {
    Component: OpenWyswyg
  },
  ArticleBodyPullQuote: {
    Component: PullQuote
  },
  ArticleBodyList: {
    Component: List
  },
  ArticleBodyInlinePortraitImage: {
    Component: InlinePortraitImage,
    staticProps: {
      className: styles.inlinePortraitImage
    }
  },
  ArticleBodyMetricsRailCard: {
    Component: MetricsRailCard,
    staticProps: {
      className: styles.metricsRailCard
    }
  },
  ArticleBodyRelatedRailCard: {
    Component: RelatedRailCard,
    staticProps: {
      className: styles.relatedRailCard
    }
  },
  ArticleBodyProjectBreaker: {
    Component: ProjectBreaker,
    staticProps: {
      className: styles.projectBreaker
    }
  },
  ArticleBodyZoomingImageBreaker: {
    Component: ZoomingImageBreaker
  },
  ArticleBodyCarousel: {
    Component: Carousel,
    staticProps: {
      className: styles.carousel
    }
  },
  ArticleBodyMediaEmbed: {
    Component: MediaEmbed,
    staticProps: {
      className: styles.mediaEmbed,
      option: 'inline'
    }
  },
  ArticleBodyFullWidthMediaEmbed: {
    Component: MediaEmbed,
    staticProps: {
      className: styles.mediaEmbed,
      option: 'fullWidth'
    }
  },
  ArticleBodyScrollJackModule: {
    Component: ScrollJackDesktop
  },
  ArticleBodyAlternatingZoomModule: {
    Component: AlternateFlex
  },
  ArticleBodyBreakerWithLinks: {
    Component: ArticleBreakerWithLinks
  },
  ArticleBodyStackedList: {
    Component: StackedList
  },
  ArticlebodyOpenTextRailCard: {
    Component: OpenTextRailCard
  },
  ArticleBodyRailCardImage: {
    Component: ImageRailCard,
    staticProps: {
      className: styles.imageRailCard
    }
  },
  ArticleBodyMetricsBreaker: {
    Component: MetricsBreaker,
    staticProps: {
      option: 'fullWidth'
    }
  }
};
