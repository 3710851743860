import React from 'react';

import styles from './styles.scss';

const Partners = ({ data, theme }) => {
  const { sinceLabel } = data;

  return (
    <article
      className={`${styles.leftRailIntroDetails} ${
        theme === 'light' ? styles.leftRailIntroDetails__light : styles.leftRailIntroDetails__dark
      } ${styles.partners} ${theme === 'light' ? styles.partners__light : styles.partners__dark}`}>
      <h2 className={styles.leftRailTitle} dangerouslySetInnerHTML={{ __html: 'Partners' }} />
      <span>Since {sinceLabel}</span>
    </article>
  );
};

export default Partners;
