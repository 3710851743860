import React, { useState, useEffect, useRef } from 'react';
import { get } from 'helpers/utilities';
import Slider from 'react-slick';
import Image from 'components/Image';
import { stripParagraphTag } from 'helpers/text';
import useReducedMotion from '../../hooks/useReducedMotion';

import styles from './styles.scss';

const BackButton = ({ onClick, customStyle }) => (
  <button
    type="button"
    className={`${styles.carousel__control} ${styles['carousel__control--left']}`}
    onClick={onClick}
    aria-label="Previous Slide">
    <span style={customStyle} />
  </button>
);

const NextButton = ({ onClick, customStyle }) => (
  <button
    type="button"
    className={`${styles.carousel__control} ${styles['carousel__control--right']}`}
    onClick={onClick}
    aria-label="Next Slide">
    <span style={customStyle} />
  </button>
);

const Carousel = props => {
  const { data, type, className } = props;

  const { isReducedMotionActive } = useReducedMotion();
  const [currentCounter, setCurrentCounter] = useState(1);
  const [noBottomMargin, setNoBottomMargin] = useState(false);
  const __carousel = useRef();
  const __counter = useRef();
  const sectionRef = useRef();

  const slideRefs = [];

  const customStyle = {
    backgroundColor: data.backgroundColor ? get(data, 'backgroundColor.hex') : null
  };
  const customElementsColor = {
    color: data.elementsColor ? get(data, 'elementsColor') : null
  };
  const customArrowColor = {
    borderColor: data.elementsColor ? get(data, 'elementsColor') : null
  };
  const imageSizes = {
    mobile: 700,
    tablet: 900,
    smallDesktop: 1200,
    largeDesktop: 1400
  };

  const settings = {
    accessibility: true,
    dots: false,
    infinite: true,
    fade: isReducedMotionActive,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    nextArrow: <NextButton customStyle={customArrowColor} />,
    prevArrow: <BackButton customStyle={customArrowColor} />,
    beforeChange,
    afterChange
  };

  // componentDidMount
  useEffect(() => {
    assembleSlides();
  }, []);

  useEffect(() => {
    const sectionElem = sectionRef.current;
    if (!sectionElem) return;

    const { nextSibling } = sectionElem;
    if (!nextSibling) return;

    if (nextSibling.hasAttribute('data-full-width')) {
      setNoBottomMargin(true);
    }
  }, [sectionRef]);

  function assembleSlides() {
    const slides = slideRefs.filter(slide => slide !== null);
    slides.map(slide => {
      const subSlide = slide;
      const parent = subSlide.parentElement.parentElement;

      if (parent.classList.contains('slick-active')) {
        subSlide.style.opacity = 1;
      } else {
        subSlide.style.opacity = 0;
      }

      return 0;
    });
  }

  function beforeChange(current, next) {
    // Just to clear the linter error on unused variables
    if (current < 0) {
      return;
    }

    __counter.current.innerHTML = next + 1;

    const slides = slideRefs.filter(slide => slide !== null);
    slides.map(slide => {
      const sub = slide;
      if (parseInt(slide.dataset.id, 10) === next) {
        sub.style.opacity = 1;
      } else {
        sub.style.opacity = 0;
      }

      return 0;
    });
  }

  function afterChange(currentIndex) {
    const currentSlide = slideRefs[currentIndex + 1];
    if (!currentSlide) return;
    currentSlide.focus();
    setCurrentCounter(currentIndex + 1);
  }

  return (
    <section
      className={`toolkit carousel ${styles.carousel} ${
        noBottomMargin ? styles.carousel__noBottomMargin : ''
      } ${type && type === 'location' ? styles['carousel--location'] : ''} ${className}`}
      style={customStyle}
      data-full-width
      ref={sectionRef}>
      {data.carouselTitle ? (
        <h2
          className={styles.carousel__title}
          style={customElementsColor}
          dangerouslySetInnerHTML={{ __html: stripParagraphTag(data.carouselTitle.content) }}
        />
      ) : null}
      <div className={`${styles.carousel__container}`} title="Carousel image slider">
        <aside
          className={styles.carousel__counter}
          style={customElementsColor}
          title={`Carousel counter, ${currentCounter} of ${data.item.length}`}>
          <span ref={__counter} aria-hidden="true">
            1
          </span>
          <span aria-hidden="true">/</span>
          <span aria-hidden="true">{data.item.length}</span>
        </aside>
        <Slider {...settings} className={styles['carousel__slides-container']} ref={__carousel}>
          {data.item.map((slide, idx) => (
            <div
              key={idx}
              data-id={idx}
              aria-label={`Current slide ${idx + 1}`}
              aria-hidden={currentCounter !== idx + 1}
              className={styles.carousel__item}
              ref={el => slideRefs.push(el)}>
              <Image
                src={get(slide, 'asset[0].url')}
                alt={get(slide, 'asset[0].title')}
                sizes={imageSizes}
              />
              {slide.caption ? (
                <div
                  className={styles['carousel__slide-detail']}
                  style={customElementsColor}
                  dangerouslySetInnerHTML={{
                    __html: stripParagraphTag(get(slide, 'caption.content'))
                  }}
                />
              ) : null}
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Carousel;
