import React, { useState, useRef, useEffect, useCallback } from 'react';
import Swipe from 'react-easy-swipe';
import { useApolloClient } from '@apollo/react-hooks';
import { get } from 'helpers/utilities';
import Card from 'components/Cards';
import EventHandler from 'helpers/EventHandler';
import { EVENT, breakpoints } from 'config/constants';
import { prop } from 'helpers/fp';
import each from 'promise-each';
import useThrottle from '../../hooks/useThrottle';

import styles from './styles.scss';
import {
  getRelatedArticleByTaxonomy,
  ArticleTypes,
  getLatestArticles,
  getUpcomingEventArticles,
  getRecentEventArticles,
  getRelatedByAccountTaxonomy
} from './relatedArticleQuery';

const pickTaxonomyTitle = prop('title');
const pickAccountTitle = prop('title');

const RelatedBreaker = props => {
  const { articleID } = props;

  const client = useApolloClient();

  const throttle = useThrottle();
  const [cards, setCards] = useState([]);
  const [caption, setCaption] = useState('Related Work');

  const originalX = useRef(0);
  const currentX = useRef(0);
  const index = useRef(0);
  const scrollDirection = useRef(null);

  const cardsRef = useRef();
  const resizeEvent = useRef(null);
  // TODO accountCardGridIDs skipped
  const skippedArticlesIDs = [];

  const getCurrentTranslateX = useCallback(() => {
    const currentTransform = window.getComputedStyle(cardsRef.current).transform;
    return currentTransform !== 'none' ? parseFloat(currentTransform.match(/[0-9-.]+/g)[4]) : 0;
  }, []);

  const getCurrentMargin = useCallback(() => {
    const currentMargin = window.getComputedStyle(cardsRef.current.children[1]).marginLeft;
    return currentMargin !== '0px' ? parseFloat(currentMargin) : 0;
  }, []);

  const getCardWidth = useCallback(() => {
    if (cardsRef.current) {
      return cardsRef.current.children[0].clientWidth + getCurrentMargin();
    }
    return 0;
  }, []);

  const currentIndex = useCallback((idx = 0) => {
    const maxCards = 3;

    let card = 0;

    if (idx < 0) {
      card = 0;
    } else if (idx > maxCards - 1) {
      card = maxCards - 1;
    } else if (window.innerWidth >= breakpoints.tablet && idx >= maxCards - 1) {
      card = idx - 1;
    } else {
      card = idx;
    }

    return card;
  }, []);

  const goToCard = useCallback((idx = 0) => {
    const id = currentIndex(idx);
    index.current = id;
    originalX.current = id * getCardWidth() * -1;
    const offsetPos = getCardWidth() / 10;
    if (cardsRef && cardsRef.current)
      cardsRef.current.style.transform = `translateX(${
        originalX.current + index.current * offsetPos
      }px)`;
  }, []);

  const resizeHandler = useCallback((_, e) => {
    if (e.width >= breakpoints.smallDesktop) {
      if (!cardsRef.current) return;
      cardsRef.current.classList.remove(`${styles.relatedBreaker__cards}--swipe`);
      cardsRef.current.style.transform = '';
    } else {
      goToCard(index.current);
    }
  }, []);

  const onSwipeStart = useCallback(() => {
    if (window.innerWidth >= breakpoints.smallDesktop) {
      return;
    }

    originalX.current = getCurrentTranslateX();
    currentX.current = 0;

    cardsRef.current.classList.remove(`${styles.relatedBreaker__cards}--swipe`);
  }, []);

  const onSwipeMove = useCallback(e => {
    if (window.innerWidth >= breakpoints.smallDesktop) {
      return false;
    }

    if (!scrollDirection.current) {
      // threshold of 5 to determine
      // what scroll direction
      if (e.y > 5 || e.y < -5) {
        scrollDirection.current = 'vertical';
      } else {
        scrollDirection.current = 'horizontal';
      }
    }

    if (scrollDirection.current === 'horizontal') {
      currentX.current = e.x;
      let newX = e.x + originalX.current;
      if ((index.current === 0 && e.x > 0) || (index.current === 2 && e.x < 0)) {
        newX = originalX.current;
      }
      cardsRef.current.style.transform = `translateX(${newX}px)`;
      return true;
    }
    return false;
  }, []);

  const onSwipeEnd = useCallback(() => {
    scrollDirection.current = null;
    if (window.innerWidth >= breakpoints.smallDesktop) {
      return;
    }

    const direction = currentX.current > 0 ? -1 : 1;

    cardsRef.current.classList.add(`${styles.relatedBreaker__cards}--swipe`);

    if (Math.abs(currentX.current) > window.innerWidth / 5) {
      goToCard((index.current += direction));
    } else {
      goToCard(index.current);
    }
  }, []);

  const fetchRelatedArticles = useCallback(
    (props, done = () => {}) => {
      const cards = [];
      let caption = 'Related Work';

      const { data = [], articleType, articleID } = props;

      if (articleType === 'Event') {
        const queryParamaters = data.reduce((res, accountTitle) => {
          res.push({
            accountTitle: pickAccountTitle(accountTitle),
            articleTypes: [
              'Event',
              'News',
              'Press',
              'AccountsAccount',
              'CaseStudiesCaseStudy',
              'ThoughtLeadership',
              'CuratedWorkCuration'
            ]
          });
          return res;
        }, []);

        Promise.resolve(queryParamaters)
          .then(
            each(async query => {
              const result = await client.query({
                query: getRelatedByAccountTaxonomy({ ...query, idNot: [articleID] })
              });
              get(result, 'data.categoriesConnection.edges[0].relatedEntries.entries', []).forEach(
                article => cards.push(article)
              );
            })
          )
          .then(async () => {
            if (cards.length >= 3) return;
            const result = await client.query({
              query: getUpcomingEventArticles({
                limit: 4,
                idNot: [...skippedArticlesIDs, ...cards.map(card => card.id), articleID]
              })
            });
            get(result, 'data.upcomingEvents', []).forEach(article => cards.push(article));
            caption = 'Recent Work';
          })
          .then(async () => {
            if (cards.length >= 3) return;
            const result = await client.query({
              query: getRecentEventArticles({
                limit: 4,
                idNot: [...skippedArticlesIDs, ...cards.map(card => card.id), articleID]
              })
            });
            get(result, 'data.entriesConnection.entries', []).forEach(article =>
              cards.push(article)
            );
            caption = 'Recent Work';
          })
          .finally(() => done({ cards, caption }));
      } else {
        const queryParamaters = data.reduce((res, taxonomy) => {
          res.push({ taxonomyTitle: pickTaxonomyTitle(taxonomy), articleTypes: [articleType] });
          res.push({
            taxonomyTitle: pickTaxonomyTitle(taxonomy),
            articleTypes: ArticleTypes.filter(article => article !== articleType)
          });
          return res;
        }, []);

        Promise.resolve(queryParamaters)
          .then(
            each(async query => {
              const result = await client.query({
                query: getRelatedArticleByTaxonomy({ ...query, idNot: [articleID] })
              });
              get(result, 'data.categoriesConnection.edges[0].relatedEntries.entries', []).forEach(
                article => cards.push(article)
              );
            })
          )
          .then(async () => {
            if (cards.length >= 3) return;
            const result = await client.query({
              query: getLatestArticles({
                limit: 4,
                idNot: [...skippedArticlesIDs, ...cards.map(card => card.id), articleID]
              })
            });
            get(result, 'data.entriesConnection.entries', []).forEach(article =>
              cards.push(article)
            );
            caption = 'Recent Work';
          })
          .finally(() => done({ cards, caption }));
      }
    },
    [client]
  );

  // did mount
  useEffect(() => {
    fetchRelatedArticles(props, data => {
      setCards(data.cards);
      setCaption(data.caption);
      resizeEvent.current = EventHandler.subscribe(EVENT.RESIZE, throttle(resizeHandler, 500));
    });

    return () => {
      if (resizeEvent.current) EventHandler.unsubscribe(resizeEvent.current);
    };
  }, []);

  // did update
  useEffect(() => {
    fetchRelatedArticles(props, data => {
      setCards(data.cards);
      setCaption(data.caption);
    });
  }, [articleID, JSON.stringify(skippedArticlesIDs)]);

  // required 3 cards
  if (cards.length < 3) {
    return null;
  }

  return (
    <section className={styles.relatedBreaker}>
      <h2 className={styles.relatedBreaker__title} dangerouslySetInnerHTML={{ __html: caption }} />
      <Swipe
        ref={el => el && (cardsRef.current = el.swiper)}
        onSwipeStart={onSwipeStart}
        onSwipeMove={onSwipeMove}
        onSwipeEnd={onSwipeEnd}
        className={styles.relatedBreaker__cards}>
        {cards.map((cardItem, idx) =>
          idx > 2 ? null : (
            <Card {...cardItem} size="standard" className={styles.relatedBreaker__card} key={idx} />
          )
        )}
      </Swipe>
    </section>
  );
};

export default RelatedBreaker;
