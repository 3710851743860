import React from 'react';
import { get } from 'helpers/utilities';
import TlDr from './TlDr';
import Accolades from './Accolades';
import ParentAccountTout from './ParentAccountTout';
import Authors from './Authors';
import PublishDate from './PublishDate';
import Links from './Links';
import Partners from './Partners';
import Resources from './Resources';
import ResourcesNew from './ResourcesNew';
import OpenWyswyg from './OpenWyswyg';
import EventDetailsContainer from './EventDetailsContainer';

const LeftRailIntroDetails = ({ data, theme = 'light' }) => {
  const typeName = get(data, '__typename');
  switch (typeName) {
    case 'LeftRailIntroDetailsTlDr':
      return <TlDr data={data} />;
    case 'LeftRailIntroDetailsAccolades':
      return <Accolades data={data} theme={theme} />;
    case 'LeftRailIntroDetailsParentAccountTout':
      return <ParentAccountTout data={data} theme={theme} />;
    case 'LeftRailIntroDetailsAuthors':
      return <Authors data={data} theme={theme} />;
    case 'LeftRailIntroDetailsPublishDate':
      return <PublishDate data={data} theme={theme} />;
    case 'LeftRailIntroDetailsLinks':
      return <Links data={data} theme={theme} />;
    case 'LeftRailIntroDetailsPartners':
      return <Partners data={data} theme={theme} />;
    case 'LeftRailIntroDetailsEventDetails':
      return <EventDetailsContainer data={data} theme={theme} />;
    case 'LeftRailIntroDetailsOpenWyswyg':
      return <OpenWyswyg data={data} theme={theme} />;
    case 'LeftRailIntroDetailsResources':
      return <Resources data={data} theme={theme} />;
    case 'LeftRailIntroDetailsResourcesNew':
      return <ResourcesNew data={data} theme={theme} />;
    default:
      return null;
  }
};

export default LeftRailIntroDetails;
