import { struct } from 'superstruct';
import { cardStruct, wysiwygStruct, assetStruct } from 'helpers/structure';

export default {
  ArticleBodyOpenWyswyg: struct.partial({
    text: wysiwygStruct
  }),
  InfoBodyInfoWysiwyg: struct.partial({
    text: wysiwygStruct
  }),
  OpenWysiwyg: struct.partial({
    text: wysiwygStruct
  }),
  ArticleBodyPullQuote: struct.partial({
    blurb: wysiwygStruct
  }),
  ArticleBodyList: struct.partial({
    listItem: struct.list([
      struct.partial({
        text: wysiwygStruct
      })
    ])
  }),
  ArticleBodyInlinePortraitImage: struct.partial({
    asset: assetStruct
  }),
  ArticleBodyMetricsRailCard: struct.partial({
    statNumber: 'string',
    statTitle: 'string'
  }),
  ArticleBodyRelatedRailCard: cardStruct,
  ArticleBodyProjectBreaker: struct.partial({
    projectBreakerCaption: wysiwygStruct,
    projectBreakerDescription: 'string'
  }),
  ArticleBodyZoomingImageBreaker: struct.partial({
    item: struct.tuple([
      struct.partial({ asset: assetStruct }),
      struct.partial({ asset: assetStruct }),
      struct.partial({ asset: assetStruct })
    ])
  }),
  ArticleBodyCarousel: struct.partial({
    item: struct.list([struct.partial({ asset: assetStruct })])
  }),
  ArticleBodyMediaEmbed: struct.partial({
    imageAsset: assetStruct
  }),
  ArticleBodyFullWidthMediaEmbed: struct.partial({
    imageAsset: assetStruct
  }),
  ArticleBodyScrollJackModule: struct.partial({
    asset: assetStruct
  }),
  ArticleBodyAlternatingZoomModule: struct.partial({
    item: struct.tuple([
      struct.partial({ asset: assetStruct }),
      struct.partial({ asset: assetStruct })
    ])
  }),
  ArticleBodyBreakerWithLinks: struct.partial({
    headline: wysiwygStruct
  }),
  ArticleBodyStackedList: struct.partial({}),
  ArticleBodyOpenTextRailCard: struct.partial({}),
  ArticleBodyRailCardImage: struct.partial({
    railCardImageAsset: assetStruct
  }),
  ArticleBodyMetricsBreaker: struct.partial({
    metricsCards: struct.list([
      struct.partial({
        digits: 'number'
      })
    ])
  })
};
