import React, { useState, useRef, useEffect, useCallback } from 'react';
import { get } from 'helpers/utilities';
import { EVENT } from 'config/constants';
import EventHandler from 'helpers/EventHandler';
import { clamp } from 'helpers/fp';
import { stripParagraphTag } from 'helpers/text';
import keyframe from 'helpers/keyframe';
import { listenOnWheel, disableBodyScroll, enableBodyScroll } from 'helpers/scroll';
import { getThrottledRAF } from 'helpers/layout';
import Image from 'components/Image';
import useAccessibility from '../../hooks/useAccessibility';
import useReducedMotion from '../../hooks/useReducedMotion';
import useBreakpoint from '../../hooks/useBreakpoint';
import layouts from './generated-layout';
import styles from './styles.scss';

const imageSizes = {
  largeDesktop: 2000,
  smallDesktop: 1440,
  tablet: 1248,
  mobile: 960
};

const getLayout = breakpoint =>
  layouts[breakpoint] || { containerStyle: {}, fromStyles: [], toStyles: [] };

const AlternateFlex = props => {
  const { data } = props;
  const { resetAccessibility, isClientPassingThroughLinks } = useAccessibility();
  const breakpoint = useBreakpoint();
  const { isReducedMotionActive } = useReducedMotion();

  const [containerStyle, setContainerStyle] = useState({});
  const containerRef = useRef();
  const scrolled = useRef(0);
  const isScrollDisabled = useRef(false);
  const offsetTop = useRef(110);
  const top = useRef(0);

  const fromStyles = useRef([]);
  const toStyles = useRef([]);

  const scrollEvent = useRef();

  const updateColumnStyle = useCallback(progress => {
    // ref={containerItemRefs[index * 2]}
    // ref={containerItemRefs[index * 2 + 1]}
    const children = [...containerRef.current.children];
    children.forEach((child, index) => {
      if (!child) return;
      Object.assign(
        child.style,
        keyframe(fromStyles.current[index], toStyles.current[index])(progress)
      );
    });
  }, []);

  const toggleBodyBackground = useCallback(() => {
    if (isScrollDisabled.current) {
      Object.assign(document.body.style, { background: 'white' });
    } else {
      Object.assign(document.body.style, { background: 'black' });
    }
  }, []);

  const resetStyle = useCallback(() => {
    if (scrollEvent.current) {
      EventHandler.unsubscribe(scrollEvent.current);
    }
    setContainerStyle({});

    const children = [...containerRef.current.children];
    children.forEach(child => {
      child.style = null;
    });
  }, []);

  const resetScrollJack = useCallback(() => {
    enableBodyScroll();
    isScrollDisabled.current = false;
    scrolled.current = 0;
  }, []);

  const onScroll = useCallback(() => {
    if (breakpoint === 'mobile' || isReducedMotionActive) return;
    // if (isClientPassingThroughLinks) return;
    const { top: containerTop, height: containerHeight } =
      containerRef.current.getBoundingClientRect();
    top.current = containerTop + (window.scrollY || window.pageYOffset) - offsetTop.current; // offset 97 for nav height;
    if (
      (containerTop <= offsetTop.current && scrolled.current === 0) ||
      (containerTop >= offsetTop.current && scrolled.current === containerHeight)
    ) {
      window.scrollTo(0, top.current);
      disableBodyScroll();
      toggleBodyBackground();
      isScrollDisabled.current = true;
    }
  }, [breakpoint, isReducedMotionActive]);

  const setAlternateFlexStyle = useCallback(() => {
    if (breakpoint === 'mobile') {
      resetStyle();
      return;
    }

    const layout = getLayout(breakpoint);
    fromStyles.current = layout.fromStyles;
    toStyles.current = layout.toStyles;
    updateColumnStyle(0);
    setContainerStyle(layout.containerStyle);

    if (scrollEvent.current) EventHandler.unsubscribe(scrollEvent.current);
    scrollEvent.current = EventHandler.subscribe(EVENT.SCROLL, onScroll);
  }, [breakpoint, onScroll]);

  const resetScrollJackOnImageFocus = () => {
    resetScrollJack();
    if (scrollEvent.current) EventHandler.unsubscribe(scrollEvent.current);
  };

  // did mount
  useEffect(() => {
    const throttledRAF = getThrottledRAF();
    setAlternateFlexStyle();
    resetScrollJack();

    const checkTabbing = e => {
      if (
        e.key === 'Tab' ||
        (e.shiftKey && e.key === 'Tab') ||
        (e.ctrlKey && e.key === 'Alt' && e.key === 'ArrowLeft') ||
        (e.ctrlKey && e.key === 'Alt' && e.key === 'ArrowRight')
      ) {
        resetScrollJack();
        EventHandler.unsubscribe(scrollEvent.current);
      }
    };

    window.addEventListener('keyup', checkTabbing);

    const unlisten = listenOnWheel({ maxDeltaTouch: 8 }, ({ deltaY, direction }) => {
      throttledRAF(() => {
        resetAccessibility();
        if (!isScrollDisabled.current || breakpoint === 'mobile' || isReducedMotionActive) return;
        const { height: maxscroll } = containerRef.current.getBoundingClientRect();
        scrolled.current += deltaY;

        scrolled.current = clamp(0, maxscroll)(scrolled.current);

        if ((window.scrollY || window.pageYOffset) !== top.current) {
          window.scrollTo(0, top.current);
        }

        const scrollY = scrolled.current / maxscroll;
        updateColumnStyle(scrollY);

        if (scrollY === 0 || scrollY === 1) {
          enableBodyScroll();
          isScrollDisabled.current = false;
          toggleBodyBackground();
          if (direction === 'up') window.scrollTo(0, top.current - 10);
          else window.scrollTo(0, top.current + 10);
        }
      });
    });

    return () => {
      unlisten();
      window.removeEventListener('keyup', checkTabbing);
      if (scrollEvent.current) EventHandler.unsubscribe(scrollEvent.current);
    };
  }, []);

  // did update
  useEffect(() => {
    setAlternateFlexStyle();
    resetScrollJack();
  }, [breakpoint]);

  useEffect(() => {
    if (isClientPassingThroughLinks) resetScrollJack();
  }, [isClientPassingThroughLinks]);

  const items = get(data, 'item', []);
  return (
    <section data-full-width>
      <div className={`wrapper ${styles.wrapper}`}>
        <div ref={containerRef} className={styles.alternate_flex} style={containerStyle}>
          {items.map((item, index) => (
            <React.Fragment key={index}>
              <div className={styles.alternate_flex__image}>
                <Image
                  src={get(item, 'asset[0].url')}
                  alt={get(item, 'asset[0].title')}
                  sizes={imageSizes}
                  onFocus={resetScrollJackOnImageFocus}
                  tabIndex={0}
                />
              </div>
              <div>
                {get(item, 'caption.content') ? (
                  <p
                    className={styles.alternate_flex__text}
                    dangerouslySetInnerHTML={{
                      __html: stripParagraphTag(get(item, 'caption.content', ''))
                    }}
                  />
                ) : null}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AlternateFlex;
