import React from 'react';
import { get } from 'helpers/utilities';

import styles from './styles.scss';

const Authors = ({ data, theme }) => {
  const { authors } = data;
  const authorLabel = get(authors, 'length') > 1 ? 'Authors' : 'Author';
  return (
    <article
      className={`${styles.leftRailIntroDetails} ${
        theme === 'light' ? styles.leftRailIntroDetails__light : styles.leftRailIntroDetails__dark
      } ${styles.authors} ${theme === 'light' ? styles.authors__light : styles.authors__dark}`}>
      <h2 className={styles.leftRailTitle} dangerouslySetInnerHTML={{ __html: authorLabel }} />
      <ul>
        {authors.map((auth, index) => (
          <li key={index}>{auth.title}</li>
        ))}
      </ul>
    </article>
  );
};

export default Authors;
