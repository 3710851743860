import { gql } from 'apollo-boost';
import { flipProp } from 'helpers/fp';
import articleHeader from '../../queries/articleHeader';

const cardFields = `
    description
    textColor
    featureCardArtwork {
        url
        title
        extension
    }
    standardCardArtwork {
        url
        title
        extension
    }
`;

const articleFields = `
    id
    title
    uri
    postDate
`;

const caseStudy = `
    ... on CaseStudiesCaseStudy {
        cardProject {
            ... on CardProjectCard {
                ${cardFields}
                caption
            }
        }
    }
`;

const thoughtLeadership = `
    ... on ThoughtLeadership {
        cardArticle {
            ... on CardArticleCard {
                ${cardFields}
                caption {
                    content
                }
            }
        }
    }
`;

const curatedWork = `
    ... on CuratedWorkCuration {
        cardArticle {
            ... on CardArticleCard {
                ${cardFields}
                caption {
                    content
                }
            }
        }
    }
`;

const news = `
    ... on News {
        ${articleHeader.news}
        cardSaysALot {
            ... on CardSaysALotCard {
                ${cardFields}
            }
        }
    }
`;

const press = `
    ... on Press {
        ${articleHeader.press}
        cardSaysALot {
            ... on CardSaysALotCard {
                ${cardFields}
            }
        }
    }
`;

const event = `
    ... on Event {
        ${articleHeader.event}
        cardSaysALot {
            ... on CardSaysALotCard {
                ${cardFields}
            }
        }
    }
`;

const account = `
    ... on AccountsAccount {
        cardAccount {
            ... on CardAccountCard {
                cardArtwork {
                    url
                    title
                }
                accountLogo {
                    url
                    title
                    extension
                }
            }
        }
    }
`;

const mapArtileTypeToCardQuery = {
  CaseStudiesCaseStudy: caseStudy,
  ThoughtLeadership: thoughtLeadership,
  CuratedWorkCuration: curatedWork,
  AccountsAccount: account
  // Event: event
  // News: news,
  // Press: press,
};

const mapEventTypeToCardQuery = {
  CaseStudiesCaseStudy: caseStudy,
  ThoughtLeadership: thoughtLeadership,
  CuratedWorkCuration: curatedWork,
  AccountsAccount: account,
  Event: event,
  News: news,
  Press: press
};

export const ArticleTypes = Object.keys(mapArtileTypeToCardQuery);
export const EventTypes = Object.keys(mapEventTypeToCardQuery);

export const getRelatedArticleByTaxonomy = ({ taxonomyTitle, articleTypes, idNot = [] }) => gql`
    query pageQuery {
        categoriesConnection(group: taxonomy title: "${taxonomyTitle}") {
            edges {
                relatedEntries(type: [${articleTypes.join(
                  ', '
                )}] limit: 4 orderBy: "postDate desc" ${
  idNot.length ? `idNot: [${idNot.join(', ')}]` : ''
}) {
                    entries {
                        ${articleFields}
                        ${articleTypes.map(flipProp(mapArtileTypeToCardQuery))}
                    }
                }
            }
        }
    }
`;

export const getLatestArticles = ({ limit = 4, idNot = [] } = {}) => gql`
    query pageQuery {
        entriesConnection(type: [${ArticleTypes.join(
          ', '
        )}] limit: ${limit} orderBy: "postDate desc" ${
  idNot.length ? `idNot: [${idNot.join(', ')}]` : ''
}) {
            entries {
                ${articleFields}
                ${ArticleTypes.map(flipProp(mapArtileTypeToCardQuery))}
            }
        }
    }
`;

export const getRelatedByAccountTaxonomy = ({ accountTitle, articleTypes, idNot = [] }) => gql`
    query pageQuery {
        categoriesConnection(group: account title: "${accountTitle}") {
            edges {
                relatedEntries(type: [${articleTypes.join(
                  ', '
                )}] limit: 4 orderBy: "postDate desc" ${
  idNot.length ? `idNot: [${idNot.join(', ')}]` : ''
}) {
                    entries {
                        ${articleFields}
                        ${articleTypes.map(flipProp(mapEventTypeToCardQuery))}
                    }
                }
            }
        }
    }
`;

export const getUpcomingEventArticles = ({ limit = 4, idNot = [] } = {}) => gql`
    query pageQuery {
        upcomingEvents(limit: ${limit} ${idNot.length ? `idNot: [${idNot.join(', ')}]` : ''}) {
            ${articleFields}
            ${event}
        }
    }
`;

export const getRecentEventArticles = ({ limit = 4, idNot = [] } = {}) => gql`
    query pageQuery {
        entriesConnection(type: [Event] limit: ${limit} orderBy: "postDate desc" ${
  idNot.length ? `idNot: [${idNot.join(', ')}]` : ''
}) {
            entries {
                ${articleFields}
                ${event}
            }
        }
    }
`;
