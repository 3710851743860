import React from 'react';
import { struct } from 'superstruct';
import { get } from 'helpers/utilities';
import { Link, withRouter } from 'react-router-dom';

// helpers
import { compose } from 'helpers/fp';

// components
import safeRender from 'components/hocs/safeRender';

import styles from './styles.scss';

const ResourceLink = ({ item, uri, filter }) => {
  const type = item.resourcesLinkType;
  const isNewBusiness = Boolean(uri && filter === undefined);
  const fileUrl = isNewBusiness
    ? `/new-business/${uri}/${encodeURIComponent(
        `${get(item, 'assetLink[0].folder.name', '').toLowerCase()}/${get(
          item,
          'assetLink[0].filename',
          ''
        )}`
      )}/sign`
    : get(item, 'assetLink[0].url', '');

  const handleAssetClick = e => {
    if (!isNewBusiness) {
      e.preventDefault();

      window
        .fetch(fileUrl)
        .then(res => res.blob())
        .then(file => {
          const tempUrl = URL.createObjectURL(file);
          const aTag = document.createElement('a');
          aTag.href = tempUrl;
          aTag.download = fileUrl.replace(/^.*[\\/]/, '');
          document.body.appendChild(aTag);
          aTag.click();
        });
    }
  };

  switch (type) {
    case 'assetLink':
      return (
        <div className={styles.resource_item}>
          <a
            className={styles.resource_title}
            href={fileUrl}
            target={isNewBusiness ? '__blank' : undefined}
            onClick={handleAssetClick}>
            {item.resourceTitle}
          </a>
          <p className={styles.resource_name}>{item.resourceName}</p>
        </div>
      );
    case 'internalLink':
      return (
        <div className={styles.resource_item}>
          <Link className={styles.resource_title} to={`/${get(item, 'internalLink[0].uri')}`}>
            {item.resourceTitle}
          </Link>
          <p className={styles.resource_name}>{item.resourceName}</p>
        </div>
      );
    case 'externalLink':
      return (
        <div className={styles.resource_item}>
          <a className={styles.resource_title} href={get(item, 'externalLink', '')}>
            {item.resourceTitle}
          </a>
          <p className={styles.resource_name}>{item.resourceName}</p>
        </div>
      );
    default:
      return null;
  }
};

const Resources = ({ data, theme, match }) => (
  <>
    {data.resourceItem.map((item, index) => (
      <section
        key={`resource-${index}`}
        className={`${styles.leftRailIntroDetails} ${
          theme === 'light' ? styles.leftRailIntroDetails__light : styles.leftRailIntroDetails__dark
        } ${styles.resources} ${
          theme === 'light' ? styles.resources__light : styles.resources__dark
        }`}>
        <h3 className={styles.resource_heading}>Resources</h3>
        <div className={styles.resource_container}>
          <ResourceLink
            item={item}
            uri={get(match, 'params.article')}
            filter={get(match, 'params.filter')}
            key={index}
          />
        </div>
      </section>
    ))}
  </>
);

const resourcesStruct = struct.partial({
  resourceItem: struct.list([
    struct.partial({
      resourceName: 'string',
      resourceTitle: 'string',
      resourcesLinkType: 'string'
    })
  ])
});

export default compose(withRouter, safeRender(resourcesStruct))(Resources);
