import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { get } from 'helpers/utilities';

import { pickArticleHeaderImageURL } from 'queries/articleHeader';

import ArticleHeader from 'components/ArticleHeader';
import ArticleBody from 'components/ArticleBody';
import CuratedPortfolioFetch from 'components/CuratedPortfolioFetch';
import Meta from 'components/Meta';

const CuratedPortfolioCaseStudyArticle = ({ setMetaDetails, entry }) => {
  const metaDetails = {
    metaSection: get(entry, 'socialMetaSection[0]'),
    entryTitle: get(entry, 'title'),
    placeholderImage: pickArticleHeaderImageURL(entry)
  };

  const articleHeaderData = get(entry, 'articleHeaderRequiredEyebrow[0]');

  const articleBodyData = get(entry, 'articleBody');
  const articleLridData = get(entry, 'leftRailIntroDetails');

  useEffect(() => {
    setMetaDetails(metaDetails);
  }, [JSON.stringify(metaDetails)]);

  return (
    <>
      <ArticleHeader data={articleHeaderData} pageData={entry} />
      <ArticleBody
        data={articleBodyData}
        pageData={entry}
        leftRailIntroDetails={articleLridData}
        theme="light"
        noScrollTracker={true}
      />
    </>
  );
};

const CuratedPortfolioCaseStudy = ({ active }) => {
  const { article, caseStudy } = useParams();
  const [metaDetails, setMetaDetails] = useState({});

  return (
    <>
      <Meta metaDetails={{ ...metaDetails, noIndex: true }} active={active} />
      <CuratedPortfolioFetch
        url={`/pitch/${article}/${caseStudy}`}
        article={article}
        onSuccess={() => window.scrollTo({ top: 0 })}>
        {entry => (
          <CuratedPortfolioCaseStudyArticle entry={entry} setMetaDetails={setMetaDetails} />
        )}
      </CuratedPortfolioFetch>
    </>
  );
};

export default CuratedPortfolioCaseStudy;
