import React from 'react';
import styles from './styles.scss';

const Overlay = ({ opacity = 0.2, color = '#000', className }) => (
  <div
    className={`${styles.overlay} ${className || ''}`}
    style={{ opacity, backgroundColor: color }}
  />
);

export default Overlay;
