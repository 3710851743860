import React from 'react';
import classNames from 'classnames/bind';
import { get } from 'helpers/utilities';

import styles from './styles.scss';

const cx = classNames.bind(styles);

const ChapterMarker = ({ data, oddEven, theme }) => {
  const chapterClass = cx('chapterMarker', { 'chapterMarker--even': !oddEven });
  const chapterNumber = get(data, 'number');
  const chapterMarkerTitle = get(data, 'chapterMarkerTitle');

  return (
    <article className={`chapterMarker ${chapterClass} ${styles.chapterMarker}--${theme}`}>
      <div className={styles.chapterMarker__wrapper}>
        {chapterNumber && (
          <h2
            className={styles.chapterMarker__number}
            dangerouslySetInnerHTML={{ __html: chapterNumber.toString() }}
          />
        )}
        {chapterMarkerTitle && (
          <h3 className={styles.chapterMarker__title}>{chapterMarkerTitle}</h3>
        )}
      </div>
    </article>
  );
};

export default ChapterMarker;
