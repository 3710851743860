import React, { createRef, useRef, useEffect } from 'react';
import Swipe from 'react-easy-swipe';
import Image from 'components/Image';
import { breakpoints } from 'config/constants';
import useReducedMotion from '../../hooks/useReducedMotion';

import styles from './styles.scss';

const imageSizes = {
  mobile: 450,
  tablet: 300,
  smallDesktop: 400,
  largeDesktop: 450
};

const ZoomingImageBreaker = props => {
  const { data, className } = props;
  const { isReducedMotionActive } = useReducedMotion();
  const { item } = data;

  // Refs
  const currentSlide = useRef(1);
  const containerRef = createRef();

  // componentDidMount
  useEffect(() => {
    containerRef.current.dataset.slide = currentSlide.current;
  }, []);

  function swipeRightHandler() {
    if (window.innerWidth < breakpoints.tablet) {
      const index = currentSlide.current > 0 ? currentSlide.current - 1 : 0;
      zoomIn(index);
    }
  }

  function swipeLeftHandler() {
    if (window.innerWidth < breakpoints.tablet) {
      const index = currentSlide.current < 2 ? currentSlide.current + 1 : 2;
      zoomIn(index);
    }
  }

  function mouseOverHandler(e) {
    const index = e.target.parentElement.dataset.slideKey;
    zoomIn(index);
  }

  function mouseOutHandler() {
    zoomIn(1);
  }

  function zoomIn(index) {
    currentSlide.current = index;
    containerRef.current.setAttribute('data-slide', currentSlide.current);
  }

  return (
    <section
      className={`${styles.zoomingImageBreaker} ${className}`}
      ref={containerRef}
      data-full-width>
      <Swipe
        className={styles.zoomingImageBreaker__container}
        onSwipeLeft={swipeLeftHandler}
        onSwipeRight={swipeRightHandler}
        tolerance={80}>
        {item.map((img, index) => (
          <div
            className={styles.zoomingImageBreaker__image}
            key={index}
            data-slide-key={index}
            onMouseOver={!isReducedMotionActive ? mouseOverHandler : ''}
            onMouseOut={!isReducedMotionActive ? mouseOutHandler : ''}
            onFocus={!isReducedMotionActive ? mouseOverHandler : ''}
            onBlur={!isReducedMotionActive ? mouseOutHandler : ''}>
            <Image
              src={img.asset[0].url}
              alt={img.asset[0].title}
              lazyload={false}
              sizes={imageSizes}
            />
          </div>
        ))}
      </Swipe>
    </section>
  );
};

export default ZoomingImageBreaker;
