import React from 'react';
import { get } from 'helpers/utilities';
import { Link } from 'react-router-dom';

import styles from './styles.scss';

const ParentAccountTout = ({ data, theme }) => {
  const { parentAccountToutLink } = data;

  return (
    <article
      className={`${styles.leftRailIntroDetails} ${
        theme === 'light' ? styles.leftRailIntroDetails__light : styles.leftRailIntroDetails__dark
      }`}>
      <Link
        to={{ pathname: `/${parentAccountToutLink[0].uri}` }}
        title={`More from ${get(parentAccountToutLink, '[0].title')}`}
        className={styles.leftRailLink}>
        More from {get(parentAccountToutLink, '[0].title')}
        <span />
      </Link>
    </article>
  );
};

export default ParentAccountTout;
