import React, { useRef, useCallback } from 'react';
import Swipe from 'react-easy-swipe';
import Card from 'components/Cards';
import useBreakpoint from '../../../hooks/useBreakpoint';

import styles from './styles.scss';

const CardList = props => {
  const direction = useRef({ value: 1 });
  const scrollDirection = useRef({ value: '' });

  const { cards, current, cardRefs, setCurrent, total, next, prev, style } = props;
  const breakpoint = useBreakpoint();

  const onSwipeStart = useCallback(() => {
    if (breakpoint !== 'mobile') return;

    cardRefs.current.forEach(el => {
      if (!el) return;
      el.style.transition = '';
    });
  }, [breakpoint]);

  const onSwipeMove = useCallback(
    e => {
      if (breakpoint !== 'mobile') return false;

      const { current: directionCurrent } = direction;
      const { current: scrollDirectionCurrent } = scrollDirection;

      directionCurrent.value = e.x < 0 ? -1 : 1;

      if (!scrollDirectionCurrent.value) {
        // threshold of 5 to determine
        // what scroll direction
        if (e.y > 5 || e.y < -5) {
          scrollDirectionCurrent.value = 'vertical';
        } else {
          scrollDirectionCurrent.value = 'horizontal';
        }
      }

      const isOnEnd =
        (directionCurrent.value === -1 && current === total - 1) ||
        (directionCurrent.value === 1 && current === 0);
      if (scrollDirectionCurrent.value === 'horizontal' && !isOnEnd) {
        cardRefs.current.forEach((el, index) => {
          if (!el) return;
          el.style.transform = `translateX(${(index - current) * 100}%) translateX(${
            (index - current) * 24
          }px) translateX(${e.x}px)`;
        });
        return true;
      }

      return false;
    },
    [current, breakpoint, total]
  );

  const onSwipeEnd = useCallback(() => {
    if (breakpoint !== 'mobile') return;

    const { current: directionCurrent } = direction;
    const { current: scrollDirectionCurrent } = scrollDirection;

    if (scrollDirectionCurrent.value === 'horizontal') {
      cardRefs.current.forEach(el => {
        if (!el) return;
        el.style.transition = 'opacity 300ms ease-in-out, transform 300ms ease-in-out';
      });
      if (directionCurrent.value === -1) next();
      else prev();
    }
    scrollDirectionCurrent.value = '';
  }, [next, prev, breakpoint]);

  return (
    <Swipe
      className={styles.cardList}
      onSwipeStart={e => onSwipeStart(e)}
      onSwipeMove={e => onSwipeMove(e)}
      onSwipeEnd={e => onSwipeEnd(e)}
      style={style}>
      {cards.map((cardData, index) => {
        if (!cardData) return null;
        return (
          <Card
            key={index}
            size="standard"
            title={cardData.title}
            uri={cardData.uri}
            isExternalLink={cardData.isExternalLink}
            cardCurated={cardData.cardProject}
            active={current === index}
            zoom={true}
            onHover={() => setCurrent(index)}
            style={{
              transition: 'opacity 300ms ease-in-out, transform 300ms ease-in-out',
              opacity: current === index ? 1 : 0.4,
              transform:
                breakpoint === 'mobile'
                  ? `translateX(${(index - current) * 100}%) translateX(${
                      (index - current) * 24
                    }px)`
                  : ''
            }}
            ref={el => (cardRefs.current[index] = el)}
          />
        );
      })}
    </Swipe>
  );
};

export default CardList;
