import React from 'react';
import { useLocation } from 'react-router-dom';
import { struct } from 'superstruct';

import { get } from 'helpers/utilities';
import { last } from 'helpers/fp';
import { wysiwygStruct, assetStruct, isStructValid } from 'helpers/structure';
import safeRender from 'components/hocs/safeRender';
import PackageCards from 'components/PackageCards';

const parseDisabledCardEntryURI = (cardEntryURI, location) => {
  const slug = last(cardEntryURI.split('/'));
  return `${location.pathname.slice(1)}/${slug}`;
};

const parseCuratedPackageCard = (card, location) => {
  const typename = get(card, '__typename');
  const commonFields = {
    imageAsset: card.imageAsset,
    clientName: card.clientName,
    projectDescription: card.projectDescription,
    videoAssetBackground: card.videoAssetBackground,
    videoBackground: card.videoBackground,
    videoCtaCopy: card.videoCtaCopy,
    videoDescription: card.videoDescription,
    videoProjectName: card.videoProjectName,
    videoType: card.videoType
  };
  if (typename === 'CardsCaseStudyEntry') {
    const cardEntryURI = get(card, 'cardEntry[0].uri', '');
    return {
      uri:
        get(card, 'cardEntry[0].status', '') === 'disabled'
          ? parseDisabledCardEntryURI(cardEntryURI, location)
          : cardEntryURI,
      cardProject: get(card, 'cardEntry[0].cardProject'),
      isExternalLink: false,
      ...commonFields
    };
  }
  if (typename === 'CardsExternalLinkEntry') {
    return {
      uri: get(card, 'entryUrl'),
      cardProject: [
        {
          caption: get(card, 'entryEyebrow'),
          description: get(card, 'entryTitle'),
          featureCardArtwork: get(card, 'entryImageAsset'),
          floatingCardArtwork: get(card, 'entryImageAsset'),
          standardCardArtwork: get(card, 'entryImageAsset'),
          textColor: 'blackText'
        }
      ],
      isExternalLink: true,
      ...commonFields
    };
  }
  return null;
};

const CuratedPackageCards = ({ data }) => {
  const location = useLocation();
  return (
    <PackageCards
      eyebrow={data.eyebrow}
      moduleTitle={data.moduleTitle}
      cards={data.cards.map(card => parseCuratedPackageCard(card, location))}
    />
  );
};

const caseStudyCardStruct = struct.partial({
  cardEntry: struct.tuple(['any'])
});

const externalCardStruct = struct.partial({
  entryEyebrow: 'string',
  entryImageAsset: assetStruct,
  entryTitle: 'string',
  entryUrl: 'string'
});

const packageCardsStruct = struct.partial({
  moduleTitle: wysiwygStruct,
  cards: struct.list([
    struct.function(card => {
      const typename = get(card, '__typename');
      if (typename === 'CardsCaseStudyEntry') return isStructValid(caseStudyCardStruct, card);
      if (typename === 'CardsExternalLinkEntry') return isStructValid(externalCardStruct, card);
      return false;
    })
  ])
});

export default safeRender(packageCardsStruct)(CuratedPackageCards);
