import { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { get } from 'helpers/utilities';
import { articleBody as articleBodyComponents, LRID } from 'queries/articleBody';
import articlesQuery from 'queries/articles';

const useArticleBody = entryData => {
  const articleBodyComponent = get(entryData, 'articleBody');
  const leftRailIntroDetailsData = get(entryData, 'leftRailIntroDetails', []);

  const bodyComponents = articleBodyComponent
    .map(c => c.__typename)
    .filter((val, i, arr) => arr.indexOf(val) === i)
    .map(c => articleBodyComponents[c])
    .join('');
  const lridComponents = leftRailIntroDetailsData
    .map(c => c.__typename)
    .filter((val, i, arr) => arr.indexOf(val) === i)
    .map(c => LRID[c])
    .join('');

  const query = articlesQuery[get(entryData, '__typename')].component(
    get(entryData, 'uri'),
    articleBodyComponents.wrapper(bodyComponents) + LRID.wrapper(lridComponents)
  );

  const { data, error } = useQuery(query);

  useEffect(() => {
    if (!data && error) console.error(error);
  }, [data, error]);

  return data;
};

export default useArticleBody;
