import React from 'react';
import Newsletter from '../Newsletter';
import styles from './styles.scss';

const EmailSignup = ({ title, body, successMessage }) => (
  <section className={styles.emailSignup} data-full-width="true">
    <h2 className={styles.emailSignup__header}>{title}</h2>
    <div
      className={styles.emailSignup__description}
      dangerouslySetInnerHTML={{ __html: body.content }}
    />
    <Newsletter
      className={styles.emailSignup__form}
      id="newsletter_article"
      successMessage={successMessage}
    />
  </section>
);

export default EmailSignup;
