import React from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { last } from 'helpers/fp';

// components
import Article from '../Article';
import Info from '../Info';

import useInfoSinglePageContent from '../../hooks/useInfoSinglePageContent';

const InfoSinglePage = ({ active }) => {
  const location = useLocation();
  const contentName = last(location.pathname.split('/'));
  const contentSingle = useInfoSinglePageContent(`single-page/${contentName}`);
  const contentInfo = useInfoSinglePageContent(`info/${contentName}`);

  if (contentSingle.loading || contentInfo.loading) return null;
  if (!contentSingle.entry && !contentInfo.entry) {
    const isDiversityPage = contentName === 'diversity-equity-inclusion';
    const shouldRedirect = isDiversityPage && typeof window !== 'undefined' && window.localStorage;

    if (shouldRedirect) {
      window.localStorage.setItem('redirect-to', '/about-us');
    }

    throw new Error(isDiversityPage ? 'Redirect' : 'No Entry');
  }

  if (contentSingle.entry) {
    return <Article active={active} prefetchedArticleContent={contentSingle.entry} />;
  }
  return <Info active={active} infoContent={contentInfo.entry} />;
};

export default withRouter(InfoSinglePage);
