import React, { useState, useEffect, createElement, useRef } from 'react';
import { get } from 'helpers/utilities';
import Odometer from './odometer';
import styles from './styles.scss';

const MeterCounter = ({ animation, duration, format, theme, value, ...rest }) => {
  const node = useRef(null);
  const odometer = useRef();

  useEffect(() => {
    odometer.current = new Odometer({
      el: node.current,
      auto: false,
      animation,
      duration,
      format,
      theme,
      value
    });
  }, []);

  useEffect(() => {
    odometer.current?.update(value);
  }, [value]);

  return createElement('div', {
    ...rest,
    ref: node
  });
};

const Meter = ({
  digits,
  textBeforeDigits,
  textAfterDigits,
  cardDescription,
  activate,
  enableAnimation
}) => {
  const [digit, setDigit] = useState(!enableAnimation ? digits : 0);

  useEffect(() => {
    if (!activate) return;
    setDigit(digits);
  }, [activate]);

  return (
    <div className={styles.metricsBreaker__meterWrap}>
      <div className={styles.metricsBreaker__meter}>
        {textBeforeDigits && <span>{textBeforeDigits}</span>}
        {digits && (
          <MeterCounter
            value={digit}
            format="(,ddd).dd"
            className={styles.metricsBreaker__odometer}
          />
        )}
        {textAfterDigits && <span>{textAfterDigits}</span>}
      </div>
      {cardDescription && (
        <div
          className={styles.metricsBreaker__description}
          dangerouslySetInnerHTML={{ __html: cardDescription.content.toString() }}
        />
      )}
    </div>
  );
};

const MetricsBreaker = ({ data }) => {
  const sectionRef = useRef();
  const [activateOdometer, setActivateOdometer] = useState(false);
  const { moduleTitle, moduleDescription, metricsCards, enableAnimation } = data;

  const activateOdometerFn = () => {
    const section = sectionRef.current;
    const rect = section?.getBoundingClientRect();
    const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    if (!(rect.bottom < 0 || rect.top - viewHeight >= 0)) {
      setActivateOdometer(true);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', activateOdometerFn);
    activateOdometerFn();

    return () => document.removeEventListener('scroll', activateOdometerFn);
  }, []);

  return (
    <section className={styles.metricsBreaker} ref={sectionRef} data-full-width={true}>
      {(moduleTitle || moduleDescription) && (
        <div className={styles.metricsBreaker__titleDescWrap}>
          {moduleTitle && <h2 className={styles.metricsBreaker__moduleTitle}>{moduleTitle}</h2>}
          {moduleDescription && (
            <div
              className={styles.metricsBreaker__moduleDescription}
              dangerouslySetInnerHTML={{ __html: get(moduleDescription, 'content', '') }}
            />
          )}
        </div>
      )}
      {metricsCards.length && (
        <div className={styles.metricsBreaker__meters}>
          {metricsCards.map((metricCard, key) => (
            <Meter
              {...metricCard}
              activate={activateOdometer}
              enableAnimation={enableAnimation}
              key={key}
            />
          ))}
        </div>
      )}
    </section>
  );
};

export default MetricsBreaker;
