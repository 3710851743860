import React from 'react';
import { useLocation } from 'react-router-dom';

import { get } from 'helpers/utilities';

import ArticleHeader from 'components/ArticleHeader';
import ArticleBody from 'components/ArticleBody';
import RelatedBreaker from 'components/RelatedBreaker';
import Contact from 'components/Contact';
import { ArticleTypes, EventTypes } from 'components/RelatedBreaker/relatedArticleQuery';
import CardGridComponent from 'components/CardGridComponent';
import AwardsModule from 'components/AwardsModule';
import Meta from 'components/Meta';
import NavigationThemeSetter from 'components/NavigationThemeSetter';
import { pickArticleHeaderImageURL } from 'queries/articleHeader';
import Features from 'components/Features';
import EmailSignup from 'components/EmailSignup';
import withStickyFilterSetter from 'components/withStickyFilterSetter';
import articleQueries from 'queries/articles';
import useAwardsContent from '../../hooks/useAwardsContent';
import useAccountCardGridContent from '../../hooks/useAccountCardGridContent';
import useArticleBody from '../../hooks/useArticleBody';
import useArticleContent from '../../hooks/useArticleContent';
import useGlobalContactContent from '../../hooks/useGlobalContactContent';
import useContact from '../../hooks/useContact';
import buildGTMData from '../../helpers/buildGTMData';

const FeaturesWithStickyFilter = withStickyFilterSetter({
  componentName: 'features',
  color: 'white'
})(Features);

const ArticleContent = ({ data, articleContentStyle }) => {
  const fullArticleData = useArticleBody(data);

  if (!fullArticleData) return null;

  const articleBodyData = get(fullArticleData, 'entry.articleBody');
  const articleLridData = get(fullArticleData, 'entry.leftRailIntroDetails');

  return (
    <ArticleBody
      data={articleBodyData}
      pageData={data}
      leftRailIntroDetails={articleLridData}
      isCuratedPortfolio={get(data, '__typename') === 'CuratedPortfolio'}
      theme={get(data, '__typename') === 'CuratedPortfolio' ? 'dark' : 'light'}
      articleContentStyle={articleContentStyle}
    />
  );
};

const ArticleCardGrid = ({ articleContent }) => {
  const accountArticles = useAccountCardGridContent(articleContent);
  if (!accountArticles) return null;
  return (
    <CardGridComponent
      transparentCard="black"
      title="<strong>All</strong> Work"
      titleStyle="no-slash"
      layout="specialized1"
      cards={accountArticles}
      className="white-bg account-page"
    />
  );
};

const ArticleAwards = ({ articleContent }) => {
  const awards = useAwardsContent(articleContent);
  if (!awards) return null;
  return <AwardsModule data={awards} />;
};

const ArticleEmailSignup = () => {
  const contactData = useGlobalContactContent();
  const { newsletterSignup } = contactData;
  const moduleData = {
    title: 'Subscribe Now',
    body: {
      content: newsletterSignup[0].newsletterSignupDescription
    }
  };

  if (!newsletterSignup) return null;

  return <EmailSignup {...moduleData} />;
};

const ContactModule = ({ data }) => {
  const contactData = useContact(data);
  return <Contact {...contactData} isMinified={false} />;
};

const Article = ({ active, prefetchedArticleContent }) => {
  const location = useLocation();

  // TO DO: Remove additional page/ pattern once page prefix is dropped
  let url = location.pathname.replace(/^\/(page\/)?/, '');
  if (url.indexOf('/') === -1) url = `single-page/${url}`;

  const articleContent = prefetchedArticleContent || useArticleContent(url);
  const gtmData = buildGTMData(articleContent);

  if (!articleContent) return null;
  if (articleContent.uri !== url) return null;

  const articleType = get(articleContent, '__typename');
  const articleID = get(articleContent, 'id');
  const [articleHeaderData] =
    get(articleContent, 'articleHeaderNoEyebrow', null) ||
    get(articleContent, 'articleHeaderOptionalEyebrow', null) ||
    get(articleContent, 'articleHeaderRequiredEyebrow', null) ||
    get(articleContent, 'articleHeaderTitleOnly', null) ||
    get(articleContent, 'articleHeader', null) ||
    [];
  const displayRelatedBreaker =
    get(articleContent, 'withRelatedWorksArticlePage', false) ||
    get(articleContent, 'withRelatedWorksSinglePage', false);
  let relatedBreakerData = [];
  let articleContentStyle = '';

  if (articleType === 'Event') {
    if (EventTypes.some(type => type === articleType)) {
      relatedBreakerData = get(articleContent, 'accountParent');
    }
  } else if (ArticleTypes.some(type => type === articleType)) {
    relatedBreakerData = get(articleContent, 'taxonomy');
  } else if (articleType === 'SinglePage') {
    relatedBreakerData = get(articleContent, 'taxonomy');
    articleContentStyle = 'singlePage';
  }

  const { accountCardGrid, awardsModule, featuresModule = [] } = articleContent;

  const metaDetails = {
    metaSection: get(articleContent, 'socialMetaSection[0]'),
    entryTitle: get(articleContent, 'title'),
    placeholderImage: pickArticleHeaderImageURL(articleContent)
  };

  const hasFeaturesModule = featuresModule && featuresModule.length > 0;
  const displayNewsletterBlock = get(articleContent, 'withEmailSignup');

  return (
    <>
      <Meta metaDetails={metaDetails} active={active} gtm={gtmData} />
      {articleHeaderData && <ArticleHeader data={articleHeaderData} pageData={articleContent} />}
      <NavigationThemeSetter navTheme="black">
        <ArticleContent data={articleContent} articleContentStyle={articleContentStyle} />
        {hasFeaturesModule && (
          <FeaturesWithStickyFilter
            features={featuresModule}
            componentQuery={articleQueries.SinglePage.spFeatures(
              get(articleContent, 'uri').split('/')[1]
            )}
          />
        )}
        {accountCardGrid && <ArticleCardGrid articleContent={articleContent} />}
        {awardsModule && <ArticleAwards articleContent={articleContent} />}
        {displayNewsletterBlock && <ArticleEmailSignup />}
      </NavigationThemeSetter>
      {displayRelatedBreaker && relatedBreakerData ? (
        <NavigationThemeSetter navTheme="white">
          <RelatedBreaker
            data={relatedBreakerData}
            articleType={articleType}
            articleID={articleID}
          />
        </NavigationThemeSetter>
      ) : null}
      <NavigationThemeSetter navTheme="white">
        <ContactModule data={articleContent} />
      </NavigationThemeSetter>
    </>
  );
};

export default Article;
