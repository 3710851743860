/**
 * This is auto generated data
 * Made by generateAlternateFlexStyle
 * on ./layout.js file.
 * This data is the layout for
 * the alternate flex, since
 * it is basically static layout
 * it is better to have it pre computed
 * before rendering.
 *
 * Ideally this should be handled
 * by compilation, but the LOE is big.
 */

export default {
  tablet: {
    containerStyle: {
      width: '87.5vw',
      height: '52.864583333333336vw',
      maxWidth: '1312px',
      maxHeight: '792.6666666666666px'
    },
    fromStyles: [
      {
        width: '65.47619047619048%',
        height: '81.2807881773399%',
        top: '0%',
        left: '0%'
      },
      {
        width: '31.101190476190478%',
        height: '14.77832512315271%',
        top: '81.2807881773399%',
        left: '0%'
      },
      {
        width: '31.101190476190478%',
        height: '38.916256157635466%',
        top: '0%',
        left: '67.87015352589123%'
      },
      {
        width: '31.101190476190478%',
        height: '14.77832512315271%',
        top: '38.916256157635466%',
        left: '67.87015352589123%'
      }
    ],
    toStyles: [
      {
        width: '31.101190476190478%',
        height: '38.608374384236456%',
        top: '0%',
        left: '0%'
      },
      {
        width: '31.101190476190478%',
        height: '14.77832512315271%',
        top: '38.608374384236456%',
        left: '0%'
      },
      {
        width: '65.47619047619048%',
        height: '81.92896033186415%',
        top: '0%',
        left: '33.49515352589123%'
      },
      {
        width: '31.101190476190478%',
        height: '14.77832512315271%',
        top: '81.92896033186415%',
        left: '33.49515352589123%'
      }
    ]
  },
  smallDesktop: {
    containerStyle: {
      width: '86.5625vw',
      height: '56.35416666666666vw',
      maxWidth: '1312px',
      maxHeight: '854.1419975932612px'
    },
    fromStyles: [
      {
        width: '74.12755716004813%',
        height: '85.95194085027728%',
        top: '0%',
        left: '0%'
      },
      {
        width: '22.14199759326113%',
        height: '11.090573012939002%',
        top: '85.95194085027728%',
        left: '0%'
      },
      {
        width: '22.14199759326113%',
        height: '25.693160813308687%',
        top: '0%',
        left: '77.38914863256461%'
      },
      {
        width: '22.14199759326113%',
        height: '11.090573012939002%',
        top: '25.693160813308687%',
        left: '77.38914863256461%'
      }
    ],
    toStyles: [
      {
        width: '22.14199759326113%',
        height: '25.673956357875028%',
        top: '0%',
        left: '0%'
      },
      {
        width: '22.14199759326113%',
        height: '11.090573012939002%',
        top: '25.673956357875028%',
        left: '0%'
      },
      {
        width: '74.12755716004813%',
        height: '86.01623402716386%',
        top: '0%',
        left: '25.40358906577762%'
      },
      {
        width: '22.14199759326113%',
        height: '11.090573012939002%',
        top: '86.01623402716386%',
        left: '25.40358906577762%'
      }
    ]
  },
  largeDesktop: {
    containerStyle: {
      width: '89.74358974358975vw',
      height: '54.567307692307686vw',
      maxWidth: '1312px',
      maxHeight: '797.7428571428571px'
    },
    fromStyles: [
      {
        width: '74.28571428571429%',
        height: '91.776798825257%',
        top: '0%',
        left: '0%'
      },
      {
        width: '22.857142857142858%',
        height: '8.81057268722467%',
        top: '91.776798825257%',
        left: '0%'
      },
      {
        width: '22.857142857142858%',
        height: '28.193832599118945%',
        top: '0%',
        left: '77.53317720530836%'
      },
      {
        width: '22.857142857142858%',
        height: '8.81057268722467%',
        top: '28.193832599118945%',
        left: '77.53317720530836%'
      }
    ],
    toStyles: [
      {
        width: '22.857142857142858%',
        height: '28.239015023156%',
        top: '0%',
        left: '0%'
      },
      {
        width: '22.857142857142858%',
        height: '8.81057268722467%',
        top: '28.239015023155993%',
        left: '0%'
      },
      {
        width: '74.28571428571429%',
        height: '91.6299559471366%',
        top: '0%',
        left: '26.10460577673693%'
      },
      {
        width: '22.857142857142858%',
        height: '8.81057268722467%',
        top: '91.6299559471366%',
        left: '26.10460577673693%'
      }
    ]
  }
};
