import React from 'react';
import { get } from 'helpers/utilities';
import { struct } from 'superstruct';

// helpers
import { wysiwygStruct, assetStruct } from 'helpers/structure';
import { setInnerHTML } from 'helpers/text';
import { compose } from 'helpers/fp';

// queries
import { getArticleHeaderVideo } from 'queries/articleHeader';

// components
import CarouselVideo from 'components/CarouselVideo';
import safeRender from 'components/hocs/safeRender';
import Overlay from 'components/Overlay';
import Logo from 'components/Logo';
import useBreakpoint from '../../hooks/useBreakpoint';
import PartnershipLogo from './PartnershipLogo';

// styles
import styles from './styles.scss';

const imageSizes = {
  largeDesktop: 2000,
  smallDesktop: 1440,
  tablet: 1248,
  mobile: 960
};

const CuratedPortfolioHeader = ({ data }) => {
  const breakpoint = useBreakpoint();
  const title = get(data, 'pageTitle.content');
  const image = get(data, 'imageBackground[0]');
  const video = getArticleHeaderVideo(data, breakpoint) || {};
  return (
    <div className={styles.curatedPortfolioHeader}>
      <section className={styles.curatedPortfolioHeader__asset}>
        <Overlay opacity={0.4} />
        <CarouselVideo
          imageURL={image.url}
          imageAlt="Carousel Hero Slide"
          grayscale={true}
          videoURL={video.url}
          imageClass={styles.curatedPortfolioHeader__image}
          imageSizes={imageSizes}
        />
      </section>
      <section className={styles.curatedPortfolioHeader__wrapper}>
        <PartnershipLogo data={data} />
        <div className={styles.curatedPortfolioHeader__cntLogo}>
          <Logo fill="white" />
        </div>
        <h2
          dangerouslySetInnerHTML={setInnerHTML(title)}
          className={styles.curatedPortfolioHeader__title}
        />
      </section>
    </div>
  );
};

const curatedPortfolioHeaderStruct = struct.partial({
  imageBackground: assetStruct,
  pageTitle: wysiwygStruct
});

export default compose(safeRender(curatedPortfolioHeaderStruct))(CuratedPortfolioHeader);
