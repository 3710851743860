import React, { useLayoutEffect, useRef, useState } from 'react';

import classNames from 'classnames/bind';
import { get } from '../../helpers/utilities';
import Image from '../Image';
import { breakpoints } from '../../config/constants';

import styles from './styles.scss';

const cx = classNames.bind(styles);

const ImageRailCard = ({ data = {}, className = '', theme }) => {
  const sectionRef = useRef();
  const [topMaskHeight, setTopMaskHeight] = useState(0);
  const [bottomMaskHeight, setBottomMaskHeight] = useState(0);
  const src = get(data, 'railCardImageAsset[0].url');
  const title = get(data, 'railCardImageAsset[0].title');
  const imgSizes = {
    mobile: 268,
    tablet: 256,
    smallDesktop: 368
  };
  const classPrefix = 'src-sites-candt-components-ImageRailCard-';

  const moduleClasses = cx(
    'imageRailCard',
    `${classPrefix}imageRailCard`,
    styles[`imageRailCard--${theme}`],
    className
  );
  const imageClasses = cx(styles.imageRailCard__image, `${className}__image`);

  useLayoutEffect(() => {
    const sectionElem = sectionRef.current;
    if (!sectionElem) return;
    const leftRailModules = [
      'src-sites-candt-components-RelatedRailCard-relatedRailCard',
      'src-sites-candt-components-ImageRailCard-imageRailCard'
    ];

    const recursivePrevElemFinderFn = elem => {
      const prevElemSibling = elem.previousSibling;

      if (!prevElemSibling) return;

      if (
        prevElemSibling.hasAttribute('data-full-width') ||
        leftRailModules.some(cn => prevElemSibling.classList.contains(cn))
      ) {
        const sectionElemTop = Math.round(
          sectionElem.querySelector('.inner-wrap').getBoundingClientRect().top
        );
        const prevElemTop = Math.round(
          prevElemSibling.querySelector('.inner-wrap')?.getBoundingClientRect().top ??
            prevElemSibling.getBoundingClientRect().top
        );
        const prevElemHeight =
          prevElemSibling.querySelector('.inner-wrap')?.offsetHeight ??
          prevElemSibling.offsetHeight;
        setTopMaskHeight(Math.ceil(sectionElemTop - (prevElemTop + prevElemHeight)));
        return;
      }

      recursivePrevElemFinderFn(prevElemSibling);
    };

    const recursiveNextElemFinderFn = elem => {
      const nextElemSibling = elem.nextSibling;
      if (!nextElemSibling) return;

      if (
        nextElemSibling.hasAttribute('data-full-width') ||
        leftRailModules.some(cn => nextElemSibling.classList.contains(cn))
      ) {
        const sectionElemTop = sectionElem.getBoundingClientRect().top;
        const sectionElemHeight = sectionElem.offsetHeight;
        const nextElemTop = Math.ceil(
          nextElemSibling.querySelector('.inner-wrap')?.getBoundingClientRect().top ??
            nextElemSibling.getBoundingClientRect().top
        );
        setBottomMaskHeight(Math.ceil(nextElemTop - (sectionElemTop + sectionElemHeight)));
        return;
      }

      recursiveNextElemFinderFn(nextElemSibling);
    };

    const maskHeightSetterFn = () => {
      const isTablet = window.innerWidth >= breakpoints.tablet;
      if (isTablet) {
        recursivePrevElemFinderFn(sectionElem);
        recursiveNextElemFinderFn(sectionElem);
      } else {
        setTopMaskHeight(0);
        setBottomMaskHeight(0);
      }
    };

    maskHeightSetterFn();
    window.addEventListener('resize', maskHeightSetterFn, true);
    window.addEventListener('scroll', maskHeightSetterFn, true);
    window.addEventListener('scrollend', maskHeightSetterFn, true);
    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('resize', maskHeightSetterFn, true);
      window.removeEventListener('scroll', maskHeightSetterFn, true);
      window.removeEventListener('scrollend', maskHeightSetterFn, true);
    };
  }, [sectionRef, breakpoints]);

  return src ? (
    <section className={moduleClasses} ref={sectionRef}>
      <div className={cx(styles.imageRailCard__imageHolder, 'inner-wrap')}>
        <div className={styles.imageRailCard__topMask} style={{ height: topMaskHeight }} />
        <Image className={imageClasses} src={src} alt={title} sizes={imgSizes} />
        <div className={styles.imageRailCard__bottomMask} style={{ height: bottomMaskHeight }} />
      </div>
    </section>
  ) : null;
};

export default ImageRailCard;
