import metaDetails from './metaDetails';

export default `
    ... on Info {
    title
    infoBody {
        __typename
        ... on InfoBodyInfoWysiwyg {
            text {
                content
            }
        }
        ... on InfoBodyInfoList {
            listItem {
                text {
                    content
                }
            }
            listType
        }
    }
    ${metaDetails}
    }
`;
