import React from 'react';
import { get } from 'helpers/utilities';
import { struct } from 'superstruct';

// components
import SpecialCardGrid from 'components/SpecialCardGrid';

// hocs
import safeRender from 'components/hocs/safeRender';

import { articleTypes } from 'config/constants';
import { wysiwygStruct } from 'helpers/structure';

import styles from './styles.scss';

const CuratedCardGrid = ({ data }) => {
  const collectionEntries = get(data, 'cardGrid.collectionEntries', []).filter(card =>
    articleTypes.some(type => type === get(card, '__typename'))
  );
  const header = get(data, 'cardGridHeader.content', '');
  return collectionEntries && collectionEntries.length ? (
    <section className={styles.curatedCardGrid}>
      <div className="wrapper">
        <h2
          className={styles.curatedCardGrid__title}
          dangerouslySetInnerHTML={{ __html: header }}
        />
        <SpecialCardGrid type="simplified" cards={collectionEntries} zoomCard={true} />
      </div>
    </section>
  ) : null;
};

const curatedCardGridStruct = struct.partial({
  cardGridHeader: wysiwygStruct,
  cardGrid: struct.partial({
    collectionEntries: struct.list(['any'])
  })
});

export default safeRender(curatedCardGridStruct)(CuratedCardGrid);
