import { compose, map, match, ifElse, always, identity, replace } from './fp';

const parseNumbers = compose(
  ifElse(identity, map(parseFloat), always([])),
  match(/[+-]?\d+(\.\d+)?/g)
);
const applyNumbers = num => {
  let i = 0;
  return replace(/[+-]?\d+(\.\d+)?/g, () => `${num[i++]}`);
};

export default (from, to) => progress =>
  Object.keys(to).reduce((a, b) => {
    const initialValues = parseNumbers(from[b]);
    const finalValues = parseNumbers(to[b]);

    const values = finalValues.map(
      (_, i) => (finalValues[i] - initialValues[i]) * progress + initialValues[i]
    );
    a[b] = applyNumbers(values)(to[b]);
    return a;
  }, {});
