import React, { useEffect, useRef, useState } from 'react';
import { get, find } from 'helpers/utilities';
import Card from 'components/Cards';
import cardStyles from 'components/Cards/styles.scss';
import { breakpoints } from '../../config/constants';

import styles from './styles.scss';

const cardType = {
  cardArticle: 'Article',
  cardProject: 'Project',
  cardAccount: 'Account',
  cardNews: 'News'
};

const getCardName = data => {
  const ct = find(Object.entries(cardType), item => data[item[0]]);
  return get(ct, '[1]');
};

const RelatedRailCard = ({ data, className, theme }) => {
  const sectionRef = useRef();
  const [maskHeight, setMaskHeight] = useState(0);
  const { card } = data;
  const cardData = get(card, '[0]');
  const label = getCardName(cardData);

  useEffect(() => {
    const sectionElem = sectionRef.current;
    if (!sectionElem) return;
    const leftRailModules = [
      'src-sites-candt-components-RelatedRailCard-relatedRailCard',
      'src-sites-candt-components-ImageRailCard-imageRailCard'
    ];

    const recursiveNextElemFinderFn = elem => {
      const nextElemSibling = elem.nextSibling;
      if (!nextElemSibling) return;

      if (
        nextElemSibling.hasAttribute('data-full-width') ||
        leftRailModules.some(cn => nextElemSibling.classList.contains(cn))
      ) {
        const sectionElemTop = sectionElem.getBoundingClientRect().top;
        const sectionElemHeight = sectionElem.offsetHeight;
        const nextElemTop = nextElemSibling.getBoundingClientRect().top;
        setMaskHeight(Math.ceil(nextElemTop - (sectionElemTop + sectionElemHeight)));
        return;
      }

      recursiveNextElemFinderFn(nextElemSibling);
    };

    const maskHeightSetterFn = () => {
      const isDesktop = window.innerWidth >= breakpoints.smallDesktop;
      if (isDesktop) {
        recursiveNextElemFinderFn(sectionElem);
      } else {
        setMaskHeight(0);
      }
    };

    maskHeightSetterFn();
    window.addEventListener('resize', maskHeightSetterFn, true);
    return () => window.removeEventListener('resize', maskHeightSetterFn, true);
  }, [sectionRef, breakpoints]);

  return (
    <article
      className={`relatedRailCard ${styles.relatedRailCard} ${
        styles[`relatedRailCard--${theme}`]
      } ${className}`}
      ref={sectionRef}>
      <h2
        className={styles.relatedRailCard__title}
        dangerouslySetInnerHTML={{ __html: `Related ${label}` }}
      />
      <Card {...cardData} size="standard" className={cardStyles['card--related-card']} />
      <div className={styles.relatedRailCard__bottomMask} style={{ height: maskHeight }} />
    </article>
  );
};

export default RelatedRailCard;
