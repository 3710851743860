import React, { useCallback } from 'react';
import { get } from 'helpers/utilities';
import { setInnerHTML } from 'helpers/text';
import Image from 'components/Image';

import styles from './styles.scss';

const PartnershipLogo = ({ data }) => {
  const partnerLogo = get(data, 'partnershipLogo[0]');
  const partnerText = get(data, 'partnershipText');
  const message = get(data, 'introductoryMessage.content', '');

  const renderPartnerShip = useCallback(() => {
    if (partnerLogo)
      return (
        <div className={styles.partnershipLogo__partnerLogo}>
          <Image src={partnerLogo.url} alt={partnerLogo.title} />
        </div>
      );
  }, [JSON.stringify(partnerLogo)]);

  return (
    <div className={styles.partnershipLogo}>
      <div className={styles.partnershipLogo__partnerContainer}>
        <div className={styles.partnershipLogo__partnerTitle}>{partnerText}</div>
        <div
          dangerouslySetInnerHTML={setInnerHTML(message)}
          className={styles.partnershipLogo__message}
        />
      </div>
      {renderPartnerShip()}
    </div>
  );
};

export default PartnershipLogo;
