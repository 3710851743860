import React from 'react';
import Card from 'components/Cards';
import { stripParagraphTag } from 'helpers/text';

import cardStyles from 'components/Cards/styles.scss';
import styles from './styles.scss';

const ProjectBreaker = ({ data, className = '', theme }) => {
  const { projectBreakerCaption, projectBreakerCard, projectBreakerDescription } = data;

  return (
    <div className={`${styles.projectBreaker} ${styles.projectBreaker}--${theme} ${className}`}>
      <div className={styles.projectBreaker__container}>
        <div className={styles.projectBreaker__content}>
          <h2
            className={styles.projectBreaker__title}
            dangerouslySetInnerHTML={{ __html: stripParagraphTag(projectBreakerCaption.content) }}
          />
          <p className={styles.projectBreaker__description}>{projectBreakerDescription}</p>
        </div>
        {projectBreakerCard.length ? (
          <Card
            {...projectBreakerCard[0]}
            size="standard"
            className={`${cardStyles.card__project_breaker} ${styles.projectBreaker__card}`}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ProjectBreaker;
